<template>
    <div class="detailsPage elMain flex" style="background-color: #F7F7F7;">
        <div class="elMainCnt" style="margin-top: 35px;">
            <el-breadcrumb separator-class="el-icon-arrow-right"
                style="font-family: PingFang SC Medium;font-weight: 500;font-size: 16px;line-height: 30px;text-align: left;color: #191919;">
                <el-breadcrumb-item :to="{ path: '/news' }">
                    新闻资讯
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    {{ receivedData.title }}
                </el-breadcrumb-item>
            </el-breadcrumb>
            <br /><br />
            <div style="background-color:#fff ">
                <div style="width: 92%;margin-left:4%;position: relative;">
                    <br /><br />
                    <p
                        style="font-family: PingFang SC Bold;font-weight: 700;font-size: 26px;text-align: left;color: #191919;">
                        {{ receivedData.title }}
                    </p><br />
                    <p
                        style="font-family: PingFang SC Medium;font-weight: 500;font-size: 13px;text-align: left;color: #8b8b8b;">
                        发布时间:&nbsp; {{ receivedData.date }}&nbsp; | &nbsp;
                        <span style="position: absolute;right: 50px;">
                            <el-dropdown @command="handleCommand">
                                <span style="bg" class="el-dropdown-link">
                                    <img src="@/assets/images/share.png" />
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-popover placement="left-start" width="200" trigger="hover">
                                        <img style="position: relative;top: 5px;" src="@/assets/images/weibo@2x.png" />

                                        <el-dropdown-item slot="reference" command="xl">

                                            <span><img style="position: relative;top: 5px;" src="@/assets/images/weibo.png" />新浪微博</span>

                                        </el-dropdown-item>
                                    </el-popover>
                                    <el-popover placement="left-start" width="200" trigger="hover">
                                        <img style="position: relative;top: 5px;" src="@/assets/images/weixin@2x.png" />

                                        <el-dropdown-item slot="reference" command="wx">

                                            <span><img style="position: relative;top: 5px;" src="@/assets/images/weixin.png" />微信</span>

                                        </el-dropdown-item>
                                    </el-popover>

                                    <el-popover placement="left-start" width="200" trigger="hover">
                                        <img style="position: relative;top: 5px;" src="@/assets/images/qq@2x.png" />

                                        <el-dropdown-item slot="reference" command="qq">

                                            <span><img style="position: relative;top: 5px;" src="@/assets/images/qq.png" />QQ</span>

                                        </el-dropdown-item>

                                    </el-popover>
                                </el-dropdown-menu>
                            </el-dropdown>

                        </span>
                        <span v-if="assist">
                            <img @click="dianzan(receivedData.id)" style="cursor: pointer;position: relative;top: 4px;"
                                src="@/assets/images/点赞.png" />&nbsp;<img @click="cai(receivedData.id)"
                                style="cursor: pointer;position: relative;top: 4px;" src="@/assets/images/cai.png" />
                        </span>
                        <span v-else>
                            <img @click="dianzan(receivedData.id)" style="cursor: pointer;position: relative;top: 4px;"
                                src="@/assets/images/dianzan.png" />&nbsp;<img @click="cai(receivedData.id)"
                                style="cursor: pointer;position: relative;top: 4px;" src="@/assets/images/cai.png" />
                        </span>

                        &nbsp;&nbsp;点赞:&nbsp;
                        {{ newCnt.assist }}&nbsp;&nbsp;&nbsp;
                        阅读量:&nbsp;
                        {{ newCnt.click }}
                    </p>
                    <br /><br /><br />
                    <div style="font-family: PingFang SC Medium;font-weight: 500;font-size: 17px !important;line-height: 30px;text-align: left;color: #191919;"
                        v-html="newCnt.content"></div>
                    <br /><br /><br />
                </div>
            </div>

            <div style="height: 150px;">

            </div>
        </div>
    </div>
</template>

<script>
import parameterApi from '@/api/parameterApi';
export default {
    name: 'detailsPage',
    data() {
        return {
            newCnt: {},
            assist: false
        }
    },
    // 访问路由参数
    computed: {
        receivedData() {
            if (this.$store.state.newData != null) {
                return this.$store.state.newData;
            }
            return 'receive'
        }
    },
    mounted() {
        if (this.$route.params.item != undefined) {
            this.$store.commit('setNewData', this.$route.params.item);
        }
        if (this.receivedData != 'receive') {
            this.getData(this.receivedData.id);
        }

    },
    methods: {
        getData(id) {
            parameterApi.getNewsData({
                params: {
                    newsId: id
                }
            }).then(response => {
                this.newCnt = response.data.obj;
                const arr = JSON.parse(this.newCnt.list);
                if (arr.includes(this.receivedData.id)) {
                    this.assist = true;
                }
            }).catch(err => {
                console.log(err);
            })
        },
        dianzan(id) {
            parameterApi.assist({
                params: {
                    newsId: id
                }
            }).then(response => {
                this.$message({
                    message: response.data.msg,
                    type: 'info',
                })
                if (response.data.flag == 1) {
                    this.newCnt.assist += 1;
                }
                this.assist = true;
            }).catch(err => {
                console.log(err);
            })
        },
        cai() {
            this.$message({
                message: "等待完善~",
                type: 'info',
            })
        },
        handleCommand(command) {
            console.log('click on item ' + command);
        }

    }
}
</script>

<style scoped>
@import url('../../../assets/css/default.css');
</style>