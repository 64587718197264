import axios from "axios";
import router from "./router";
import { MessageBox } from "element-ui"
axios.defaults.baseURL = "api";


//添加相应拦截器
axios.interceptors.response.use(
    (response) => {
        if (response.data != null && response.data.code == 401) {
            MessageBox.alert('登录已过期,请重新登陆', {
                confirmButtonText: '确定',
                callback: () => {
                    // 跳转到登录页面
                    localStorage.removeItem('token');
                    router.push('/login');

                }
            })
        } else if (response.data != null && response.data.code == 402) {
            console.log(localStorage.getItem('token'));
            localStorage.removeItem('token');

            localStorage.setItem('token',response.data.token);
            console.log(localStorage.getItem('token'));
            // 在这里获取原始请求的配置
            const originalRequestConfig = response.config;
            // 重新发起请求
            return axios(originalRequestConfig);
        }
        return response;
    },
    (error =>{
        console.log(error);
        // router.push('/error')
    })

)

// 添加请求拦截器
axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token'); // 假设你将token存储在本地存储中

    if (token) {
        // 如果存在token，则将token添加到请求头中
        config.headers['token'] = token;
    }

    return config;
}, error => {
    return Promise.reject(error);
});


export default {

    get(url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, data, config)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    },
    post(url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, data, config)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    },
    delete(url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios.delete(url, data, config)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    },
    put(url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios.put(url, data, config)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    },

};

