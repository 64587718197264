import { render, staticRenderFns } from "./newDetailsView.vue?vue&type=template&id=63990446&scoped=true"
import script from "./newDetailsView.vue?vue&type=script&lang=js"
export * from "./newDetailsView.vue?vue&type=script&lang=js"
import style0 from "./newDetailsView.vue?vue&type=style&index=0&id=63990446&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63990446",
  null
  
)

export default component.exports