<template>
  <div class="servicePage">
    <!-- 咨询按钮,固定 -->
    <buttonView @childEvent="$emit('childEvent')"></buttonView>

    <div class="HeaderBG">
      <img
        style="position: absolute; width: 100vw; height: 430px"
        src="@/assets/images/6.2.png"
      />
      <!-- 背景图内容 -->
      <div class="flex" style="color: #191919">
        <div style="position: absolute; width: 1290px; top: 210px">
          <span class="carouselBlank">嘉信瑞产品</span>

          <br /><br />

          <span class="carouselDes">嘉信瑞已经为您服务了十四载</span>

          <br /><br />
          <a
            class="carouselBtn"
            style="color: none"
            href="#"
            @click="
              $emit('childEvent');
              return false;
            "
          >
            <img style="cursor: pointer" src="@/assets/images/zixun(2).png" />
          </a>
        </div>
      </div>
    </div>

    <!-- 头部 -->
    <div class="divNav flex">
      <div
        class="divOAT"
        style="background-color: #fff; top: 0px; box-shadow: none"
      >
        <ul style="list-style: none">
          <!-- 遍历展示 -->
          <span v-for="divContent in divNav" :key="divContent.id">
            <li class="divOAT_li" style="width: 33.333%">
              <!-- 最后一个时,不要边框 -->
              <span v-if="divContent.id == 3">
                <div class="divOAT_li_content float-right" style="border: none">
                  <!-- 文字 -->
                  <div
                    class="divOAT_li_content_text"
                    style="float: none; left: -30px"
                  >
                    <span class="li_content_text_blank">
                      <a
                        style="color: #191919"
                        href="#"
                        @click="
                          $emit('childEvent');
                          return false;
                        "
                      >
                        {{ divContent.blank }}
                      </a>
                    </span>
                    <br />
                    <span
                      style="
                        position: relative;
                        top: 5px;
                        font-weight: 500;
                        color: #8b8b8b;
                        font-size: 15px;
                      "
                    >
                      {{ divContent.text }}
                    </span>
                  </div>
                </div>
              </span>
              <span v-else>
                <div class="divOAT_li_content float-right">
                  <!-- 文字 -->
                  <div
                    class="divOAT_li_content_text"
                    style="float: none; left: -30px"
                  >
                    <span class="li_content_text_blank">
                      <a
                        style="color: #191919"
                        href="#"
                        @click="
                          $emit('childEvent');
                          return false;
                        "
                      >
                        {{ divContent.blank }}
                      </a>
                    </span>
                    <br />
                    <span
                      style="
                        position: relative;
                        top: 5px;
                        font-weight: 500;
                        color: #8b8b8b;
                        font-size: 15px;
                      "
                    >
                      {{ divContent.text }}
                    </span>
                  </div>
                </div>
              </span>
            </li>
          </span>
        </ul>
      </div>
    </div>

    <!-- 内容 -->
    <div class="elMain flex" style="position: relative">
      <div class="elMainCnt">
        <div style="height: 110px">
          <div :style="position1">
            <span style="font-family: PingFang SC Medium; font-size: 32px"
              >了解所有嘉信瑞产品</span
            ><br /><br />
            <span
              style="
                font-family: PingFang SC Medium;
                font-size: 16px;
                color: #8b8b8b;
              "
              >专业客服为您解答财税问题</span
            ><br /><br /><br />
          </div>
        </div>

        <el-container>
          <!-- 左边 -->
          <el-aside width="300px" style="overflow: hidden">
            <el-menu
              class="el-menu-vertical-demo custom-menu"
              mode="vertical"
              text-color="#191919"
              active-text-color="#C4171E"
              :style="position"
            >
              <!-- 遍历菜单 -->
              <span v-if="product == 1">
                <el-empty description="暂无数据"></el-empty>
              </span>
              <span v-else>
                <span v-for="item in product" :key="item.id">
                  <span v-if="item.pid == null">
                    <a style="text-decoration: none" :href="'#' + item.title">
                      <el-menu-item :index="item.title">
                        <div
                          @click="select(item.id)"
                          style="
                            position: absolute;
                            width: 100%;
                            left: 0px;
                            border: 1px;
                          "
                          slot="title"
                        >
                          <span :class="{ select: item.id == navFlag }"
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                              item.title
                            }}</span
                          >
                        </div>
                      </el-menu-item>
                    </a>
                  </span>
                </span>
              </span>
            </el-menu>
          </el-aside>
          <!-- 右边 -->
          <el-main style="padding: 0; position: relative; top: -210px">
            <span v-if="product == 1">
              <el-empty description="暂无数据"></el-empty>
            </span>
            <span v-else>
              <!-- 拿到标题 -->
              <span v-for="item in product" :key="item.id">
                <span v-if="item.pid == null">
                  <div :id="item.title" style="height: 80px"></div>
                  <p class="productTitle" slot="title">
                    {{ item.title }}
                    <br /><br />
                  </p>

                  <!-- 遍历拿到标题的所属栏目 -->
                  <div style="overflow: hidden">
                    <ul style="list-style: none">
                      <span v-for="li in product" :key="li.id">
                        <span v-if="li.pid != null && li.pid == item.id">
                          <li class="twoMainLi" style="margin-bottom: 30px">
                            <div
                              style="
                                position: relative;
                                left: 10%;
                                top: 20%;
                                width: 100%;
                                height: 60%;
                                text-align: left;
                              "
                            >
                              <p style="font-weight: 700; font-size: 15px">
                                <span style="width: 150px; overflow: hidden">{{
                                  li.title
                                }}</span
                                >&nbsp;
                                <span v-if="li.isTop == 1">
                                  <img
                                    style="position: relative; top: 5px"
                                    src="@/assets/images/remen.png"
                                  />
                                </span>
                              </p>
                              <br /><br />
                              <el-popover
                                placement="left"
                                :title="li.title"
                                width="320"
                                trigger="hover"
                                :content="li.productDesc"
                              >
                                <p
                                  slot="reference"
                                  style="cursor: pointer"
                                  class="productText"
                                >
                                  {{ li.productDesc }}
                                </p>
                              </el-popover>
                            </div>
                          </li>
                        </span>
                      </span>
                    </ul>
                  </div>
                </span>
              </span>
            </span>
          </el-main>
        </el-container>
      </div>
    </div>

    <div style="height: 100px"></div>
  </div>
</template>
  

<script>
import buttonView from "@/components/buttonView";
export default {
  components: {
    buttonView,
  },
  name: "servicePage",
  data() {
    return {
      divNav: [
        {
          id: 1,
          blank: "免费咨询 ",
          text: "专业客服为你解答财税问题",
        },
        {
          id: 2,
          blank: "免费咨询 ",
          text: "专业客服为你解答财税问题",
        },
        {
          id: 3,
          blank: "免费咨询 ",
          text: "专业客服为你解答财税问题",
        },
      ],
      navFlag: 8,
      position: "",
      position1: "",
      scrollPosition: 0,
    };
  },
  computed: {
    product() {
      return this.$store.state.product;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // 在组件销毁前移除滚动事件监听器，以防止内存泄漏
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    select(id) {
      this.navFlag = id;
      console.log(this.navFlag);
    },
    handleScroll() {
      // 更新滚动位置
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > 600) {
        this.position = "position:fixed;top:220px;width:300px";
        this.position1 = "position:fixed;top:100px;";
      } else {
        this.position = "";
        this.position1 = "";
      }
    },
  },
};
</script>

<style scoped>
@import url("../../assets/css/default.css");

.divNav {
  position: relative;
  width: 100vw;
  height: 118px;
  text-align: center;
}

.select {
  color: #c4171e;
  border-left: 2px solid #c4171e;
}

.el-menu {
  border: none !important;
  border-left: 1px solid #e3e2e2 !important;
}

/* 选中后 */
.custom-menu .el-menu-item.is-active.is-active,
/* 选中时 */
.custom-menu .el-submenu.is-active {
  background-color: rgba(0, 0, 0, 0) !important;
}
.productText {
  font-family: PingFang SC Medium;
  width: 80%;
  height: 70px;
  margin-top: -30px;
  font-size: 15px;
  color: #696d76;
  font-weight: 500;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
  -webkit-line-clamp: 3; /*则表示超出4行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号；*/
  -webkit-box-orient: vertical;
}
.productTitle {
  position: relative;
  margin-top: 25px;
  left: 30px;
  width: 200px;
  font-family: PingFang SC Bold;
  font-weight: 700;
  font-size: 22px;
  text-align: left;
  color: #191919;
}
</style>


  

  

