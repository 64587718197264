<template>
  <div class="contactMePage">
    <buttonView @childEvent="$emit('childEvent')"></buttonView>

    <div class="HeaderBG">
      <img style="width: 100vw; height: 430px" src="@/assets/images/5_1.png" />
      <!-- 背景图内容 -->
      <div class="flex" style="color: #191919">
        <div style="position: absolute; width: 1290px; top: 210px">
          <span class="carouselBlank">联系我们</span>

          <br /><br />

          <span class="carouselDes">周一至周五9:00-17:30，专业人士为您线上/线下 1v1 解答</span>

          <br /><br />
          <a class="carouselBtn" style="color: #191919" href="#" @click="
              $emit('childEvent');
              return false;
            ">
            <img style="cursor: pointer" src="@/assets/images/zixun(2).png" />
          </a>
        </div>
      </div>
    </div>

    <div class="elMain flex">
      <div class="elMainCnt">
        <div>
          <span style="
              position: relative;
              right: -600px;
              font-family: PingFang SC bold;
              font-weight: 700;
              font-size: 34px;
            ">联系我们</span><br /><br /><br />
        </div>

        <div>
          <el-container>
            <el-aside width="500px">
              <div>
                <p style="
                    font-family: PingFang SC Bold;
                    font-weight: 700;
                    font-size: 22px;
                  ">
                  公司地址
                </p>
                <p>&nbsp;</p>
                <p style="
                    font-family: PingFang SC Medium;
                    font-weight: 500;
                    font-size: 18px;
                  ">
                  深圳市福田区华强北街道华航社区深南中路3006号佳和华强大厦B座28层01-03房
                </p>
              </div>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <div>
                <p style="
                    font-family: PingFang SC Bold;
                    font-weight: 700;
                    font-size: 22px;
                  ">
                  联系电话
                </p>
                <p>&nbsp;</p>
                <p style="
                    font-family: PingFang SC Medium;
                    font-weight: 500;
                    font-size: 18px;
                  ">
                  客服电话：
                  <span style="color: #c4171e">0755-8329-5131</span>
                </p>
              </div>
            </el-aside>
            <el-main style="padding: 0">
              <div :style="{ backgroundImage: 'url(' + dituImg + ')' }" style="height: 390px; width: 778px">
                <!--地图-->
                <div id="bai-du-map"></div>
              </div>
            </el-main>
          </el-container>
        </div>
        <!-- 用来撑起高度 -->
        <div style="height: 150px"></div>
        <div>
          <span style="
              position: relative;
              right: -600px;
              font-family: PingFang SC bold;
              font-weight: 700;
              font-size: 34px;
            ">办公地点 </span><br /><br /><br />
          <div style="position: relative; top: 20px; overflow: hidden">
            <ul style="list-style: none">
              <span v-for="o in offices" :key="o.id">
                <span v-if="o.id % 2 != 0 && o.id == 1">
                  <li class="float-left" style="
                      width: 640px;
                      position: relative;
                      margin-bottom: 60px;
                    ">
                    <p class="title" style="color: #c4171e">
                      {{ o.companyName }}
                    </p>
                    <br />
                    <p class="info">地址:&nbsp;&nbsp;{{ o.address }}</p>
                    <p class="info">电话:&nbsp;&nbsp;{{ o.phone }}</p>
                    <p class="info">QQ:&nbsp;&nbsp;{{ o.qq }}</p>
                    <p class="info">邮箱:&nbsp;&nbsp;{{ o.email }}</p>
                    <p class="info">微信:&nbsp;&nbsp;{{ o.weChat }}</p>
                  </li>
                </span>
                <span v-else-if="o.id % 2 != 0">
                  <li class="float-left" style="
                      width: 640px;
                      position: relative;
                      margin-bottom: 60px;
                    ">
                    <p class="title">{{ o.companyName }}</p>
                    <br />
                    <p class="info">地址:&nbsp;&nbsp;{{ o.address }}</p>
                    <p class="info">电话:&nbsp;&nbsp;{{ o.phone }}</p>
                    <p class="info">QQ:&nbsp;&nbsp;{{ o.qq }}</p>
                    <p class="info">邮箱:&nbsp;&nbsp;{{ o.email }}</p>
                    <p class="info">微信:&nbsp;&nbsp;{{ o.weChat }}</p>
                  </li>
                </span>
                <span v-else>
                  <li class="float-left" style="
                      width: 640px;
                      position: relative;
                      margin-bottom: 60px;
                      left: 100px;
                    ">
                    <p class="title">{{ o.companyName }}</p>
                    <br />
                    <p class="info">地址:&nbsp;&nbsp;{{ o.address }}</p>
                    <p class="info">电话:&nbsp;&nbsp;{{ o.phone }}</p>
                    <p class="info">QQ:&nbsp;&nbsp;{{ o.qq }}</p>
                    <p class="info">邮箱:&nbsp;&nbsp;{{ o.email }}</p>
                    <p class="info">微信:&nbsp;&nbsp;{{ o.weChat }}</p>
                  </li>
                </span>
              </span>
            </ul>
          </div>
        </div>
        <!-- 用来撑起高度 -->
        <div style="height: 100px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import parameterApi from "@/api/parameterApi";
import buttonView from "@/components/buttonView";

window._AMapSecurityConfig = {
  securityJsCode: "0db33c1e15e793aca7dde57a38ebf218",
};
export default {
  components: {
    buttonView,
  },
  name: "contactMePage",
  data() {
    return {
      dituImg: require("@/assets/images/a332c70e0ee5123742a4aad28255b4a0.png"),
      offices: [
        {
          id: 1,
          companyName: "深圳总部",
          address: "深圳市福田区深南中路佳和华强大厦B座25楼",
          phone: "0755-83295048   0755-83295223",
          email: "hjcr@hjcr88.com",
          qq: "3046332866  2967543856",
          weChat: "hjcr18818990085",
        },
        {
          id: 2,
          companyName: "上海分公司",
          address: "上海市闵行区吴宝路255号力国大楼229、239室",
          email: "暂无",
          phone: "021-67663177  13671976331",
          qq: "3365787821  576723599",
          weChat: "cairunSH",
        },
      ],
      map: null,
      mouseTool: null,
      overlays: [],
      auto: null,
      placeSearch: null,
      marker: null,
    };
  },
  computed: {
    ditu() {
      return this.$store.state.ditu;
    },
  },
  mounted() {
    console.log(this.ditu);
    if (this.ditu >= 2) {
      this.$store.commit("clearDitu");
      location.reload();
    }
    this.getOffice();
    this.initMap();
  },
  methods: {
    getOffice() {
      parameterApi
        .getOffice()
        .then((response) => {
          this.offices = response.data.obj;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    initMap() {
      AMapLoader.load({
        key: "", //88dfc3c038ed3aa9d2deba37446bd675
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.marker = new AMap.Marker({
            icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
            position: [114.08466965012357, 22.54115254489021],
            anchor: "bottom-center",
          });
          // 初始化地图
          this.map = new AMap.Map("bai-du-map", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 14, // 初始化地图级别
            center: [114.08466965012357, 22.54115254489021], //中心点坐标  郑州
            resizeEnable: true,
          });
          this.map.add(this.marker);
          this.map.setFitView();
          this.$store.commit("setDitu", this.$store.state.ditu + 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
@import url('../../assets/css/default.css');

#bai-du-map {
  overflow: hidden;
  width: 778px;
  height: 390px;
  margin: 0;
  font-family: '微软雅黑';
}

/* 隐藏高德logo  */
.amap-logo {
  display: none !important;
}

/* 隐藏高德版权  */
.amap-copyright {
  display: none !important;
}

.title {
  font-family: PingFang SC Bold;
  font-weight: 700;
  font-size: 17px;
  line-height: 30px;
  text-align: left;
  color: #191919;
}

.info {
  font-family: PingFang SC Medium;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  text-align: left;
  color: #191919;
}
</style>
