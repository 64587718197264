<template>
  <div class="newsPage">
    <div class="HeaderBG">
      <img
        style="width: 100vw; height: 430px"
        src="@/assets/images/8_1_0000.png"
      />
      <!-- 背景图内容 -->
      <div class="flex" style="color: #191919">
        <div style="position: absolute; width: 1290px; top: 210px">
          <span class="carouselBlank">新闻资讯</span>

          <br /><br />

          <span class="carouselDes"
            >财税新闻实时更新，让您紧握机遇，充分享受税收红利。</span
          >

          <br /><br />
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <div class="elMain flex" style="position: relative">
      <div class="elMainCnt">
        <el-container>
          <!-- 左边 -->
          <el-aside width="300px" style="overflow: hidden">
            <el-menu
              class="el-menu-vertical-demo custom-menu"
              mode="vertical"
              text-color="#191919"
              active-text-color="#C4171E"
              :style="position"
            >
              <!-- 遍历菜单 -->
              <span v-if="newsType == null">
                <el-empty description="暂无数据"></el-empty>
              </span>
              <span v-else>
                <span v-for="item in newsType" :key="item.id">
                  <el-menu-item>
                    <div
                      @click="select(item.id)"
                      style="
                        position: absolute;
                        width: 100%;
                        left: 0px;
                        border: 1px;
                        font-size: 16px;
                      "
                      slot="title"
                    >
                      <span :class="{ select: item.id == navFlag }"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                          item.typeName
                        }}
                      </span>
                    </div>
                  </el-menu-item>
                </span>
              </span>
            </el-menu>
          </el-aside>
          <!-- 右边 -->
          <el-main style="padding: 0">
            <span v-if="news.totalCount == 0 || news == 0">
              <el-empty description="暂无数据"></el-empty>
            </span>
            <span v-else>
              <!-- 拿到标题 -->
              <span v-for="item in news.object" :key="item.id">
                <div style="margin-bottom: 78px">
                  <router-link
                    :to="{ name: 'newDetailsView', params: { item: item } }"
                  >
                    <p
                      style="
                        font-family: PingFang SC Bold;
                        font-weight: 700;
                        font-size: 17px;
                        text-align: left;
                        color: #c4171e;
                        clear: both;
                      "
                    >
                      {{ item.title }}
                    </p>
                  </router-link>

                  <p
                    style="
                      font-family: PingFang SC Medium;
                      font-weight: 500;
                      font-size: 13px;
                      text-align: left;
                      color: #8b8b8b;
                      margin-top: 12px;
                    "
                  >
                    {{ item.simpleDes }}
                  </p>
                  <div
                    class="float-left keyWord"
                    v-for="(itemWork, index) in item.keyWords"
                    :key="index"
                  >
                    <span style="padding: 10px">{{ itemWork }}</span>
                  </div>

                  <div style="clear: both; margin-top: 55px">
                    <div
                      class="float-left"
                      style="
                        height: 24px;
                        border-radius: 2px;
                        background: rgba(226, 35, 25, 0.1);
                        margin-right: 20px;
                      "
                    >
                      <span
                        style="
                          color: #c4171e;
                          padding: 10px;
                          font-weight: 500;
                          font-size: 13px;
                          text-align: left;
                        "
                        >获得{{ item.assist }}赞</span
                      >
                    </div>

                    <div
                      class="float-left"
                      style="
                        height: 24px;
                        line-height: 24px;
                        font-family: PingFang SC Medium;
                        font-weight: 500;
                        font-size: 13px;
                        text-align: left;
                        color: #8b8b8b;
                      "
                    >
                      {{ item.date }}
                    </div>
                  </div>
                </div>
              </span>
              <div
                style="height: 50px"
                v-if="Math.ceil(news.totalCount / pageSize) > 1"
              >
                <div class="flex" style="position: relative; margin-top: 51px">
                  <span>
                    <el-button
                      @click="goPrev()"
                      :disabled="prev"
                      style="padding: 8px; width: 93px"
                      ><img
                        src="@/assets/images/arrow-down(3).png"
                        style="position: relative; top: 2px"
                      />&nbsp;&nbsp;上一页</el-button
                    >
                  </span>

                  <el-pagination
                    @current-change="handleCurrentChange"
                    background
                    :current-page="pageNum"
                    :page-size="pageSize"
                    layout="pager"
                    :total="news.totalCount"
                  >
                  </el-pagination>

                  <span
                    ><el-button
                      :disabled="next"
                      style="padding: 8px; width: 93px"
                      @click="goNext()"
                    >
                      下一页&nbsp;&nbsp;<img
                        src="@/assets/images/arrow-down.png"
                        style="position: relative; top: 2px" /></el-button
                  ></span>

                  <span
                    style="
                      position: relative;
                      left: 15px;
                      top: 5px;
                      font-size: 15px;
                    "
                  >
                    <span style="color: #c4171e">{{ pageNum }}</span
                    >/{{ Math.ceil(news.totalCount / pageSize) }}
                  </span>

                  <span style="position: relative; left: 30px; top: -3px">
                    <span
                      style="
                        color: #8b8b8b;
                        font-family: PingFang SC Medium;
                        font-weight: 500;
                        font-size: 15px;
                      "
                      >到第</span
                    >
                    <el-input
                      type="text"
                      v-model="search"
                      style="
                        width: 48px;
                        margin-left: 15px;
                        margin-right: 15px;
                        padding: 0px;
                      "
                    ></el-input>
                    <span
                      style="
                        color: #8b8b8b;
                        font-family: PingFang SC Medium;
                        font-weight: 500;
                        font-size: 15px;
                      "
                      >页</span
                    >

                    <el-button
                      @click="searchPage(search)"
                      style="
                        padding: 8px;
                        width: 53px;
                        color: #191919;
                        margin-left: 15px;
                      "
                      >确定</el-button
                    >
                  </span>
                </div>
              </div>
            </span>
          </el-main>
        </el-container>

        <div style="height: 150px"></div>
      </div>
    </div>
  </div>
</template>
  
<script>
import parameterApi from "@/api/parameterApi";
export default {
  name: "newsPage",
  data() {
    return {
      navFlag: 1,
      currentPage: 1,
      pageNum: 1,
      pageSize: 10,
      news: 0,
      position: "",
      prev: null,
      next: null,
      search: null,
    };
  },
  computed: {
    param() {
      if (this.$store.state.param != null) {
        return this.$store.state.param;
      }
      return "param";
    },
    newsType() {
      return this.$store.state.newsType;
    },
  },
  mounted() {
    this.getNews(1);
    window.addEventListener("scroll", this.handleScroll);
    this.handleCurrentChange(this.pageNum);
  },
  beforeDestroy() {
    // 在组件销毁前移除滚动事件监听器，以防止内存泄漏
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 更新滚动位置
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > 700) {
        this.position = "position:fixed;top:120px;width:300px";
      } else {
        this.position = "";
      }
    },
    handleCurrentChange(val) {
      let pageNum = Math.ceil(this.news.totalCount / this.pageSize);
      this.next = pageNum == val;
      this.prev = val == 1;
      this.pageNum = val;
      this.getNews(this.navFlag);
      pageNum = null;
    },
    searchPage(page) {
      if (page < 1) {
        this.$message({
          message: "请输入大于0的数",
          type: "info",
          duration: 1000,
        });
        return;
      }
      page = parseInt(page);
      const msg = isNaN(page)
        ? "请输入数字"
        : Math.ceil(this.news.totalCount / this.pageSize) < page
        ? "超过最大页数"
        : null;
      console.log(msg);

      if (msg != null) {
        this.$message({
          message: msg,
          type: "info",
          duration: 1000,
        });
        return;
      }

      const number = parseInt(page);
      this.handleCurrentChange(number);
      this.search = null;
    },
    goNext() {
      this.handleCurrentChange(this.pageNum + 1);
    },
    goPrev() {
      this.handleCurrentChange(this.pageNum - 1);
    },
    select(id) {
      this.navFlag = id;
      this.pageNum = 1;
      this.search = null;
      this.getNews(id);
    },
    getNews(id) {
      parameterApi
        .getNewsPage({
          params: {
            id: id,
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          },
        })
        .then((response) => {
          this.news = response.data.obj;
          this.news.object;
          this.news.object.forEach((element) => {
            element.keyWords = element.keyWords.split(",");
            element.keyWords.splice(element.keyWords.length - 1, 1);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  
<style scoped>
@import url("../../assets/css/default.css");

.divNav {
  position: relative;
  width: 100vw;
  height: 118px;
  text-align: center;
}

.select {
  color: #c4171e;
  border-left: 2px solid #c4171e;
}

.el-menu {
  border: none !important;
  border-left: 1px solid #e3e2e2 !important;
}

::v-deep .el-pagination.is-background .el-pager li {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ff2832;
}
.keyWord {
  border: 1px solid #dedede;
  border-radius: 2px;
  margin-top: 12px;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  margin-right: 20px;
}
</style>
  

