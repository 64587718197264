import Vue from 'vue'
import VueRouter from 'vue-router'


import manageRouters from '../router/manage'
import homeRouters from '../router/home'

Vue.use(VueRouter)

const routes = [
    ...homeRouters,
    ...manageRouters

]

const router = new VueRouter({
    mode: 'history',
    routes
})



// 添加路由守卫
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    document.title = to.meta.title || '皇嘉后台管理系统' // 如果没有设置页面标题，则使用默认标题
    const token = localStorage.getItem('token') // 从LocalStorage中获取token
    const path = to.path.substring(1)
    //限制需要登录才能访问的页面
    const list = ['manage', 'aboutMg', 'indexMg', 'contactMg', 'customMg', 'financeMg', 'workMg', 'serviceMg', 'newsMg']
    list.forEach(element => {
        if (path == element && token == null) {
            next('/login') // 登录页面的路由路径
        }
    })
    next()

})

export default router
