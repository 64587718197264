<template>
  <div
    class="indexPage"
    style="width: 100vw; min-width: 1288px; overflow: hidden; z-index: -20"
  >
    <!-- 立即咨询按钮,固定 -->
    <div
      style="
        position: fixed;
        z-index: 100;
        cursor: pointer;
        right: 50px;
        top: 500px;
      "
    >
      <a
        style="color: none"
        href="#"
        @click="
          $emit('childEvent');
          return false;
        "
      >
        <img style="border-radius: 50%" src="@/assets/images/ljzx.png" />

        <div
          style="
            height: 27px;
            width: 73px;
            background: #fff;
            position: relative;
            top: -18px;
            left: 2.5px;
          "
        >
          <p
            style="
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                0 0 6px rgba(0, 0, 0, 0.04);
              font-family: PingFang SC Medium;
              font-weight: 500;
              font-size: 15px;
              text-align: left;
              color: #c4171e;
              line-height: 28px;
            "
          >
            <span style="padding: 5.5px">立即咨询</span>
          </p>
        </div>
      </a>
    </div>

    <!-- 滚动图 -->
    <div class="divOne" id="divOne">
      <!-- 滚动图 -->
      <div class="oneCarousel" style="position: relative; left: 4px">
        <el-carousel
          :interval="5000"
          arrow="never"
          height="586px"
          ref="myCarousel"
        >
          <!-- 遍历数组 -->
          <el-carousel-item v-for="col in carousels" :key="col.id">
            <div
              class="carouselImg"
              :style="{ backgroundImage: 'url(' + col.imgUrl + ')' }"
              style="background-size: cover"
            ></div>

            <div class="carouselText flex" style="width: 100vw">
              <!-- 滚动图内内容 -->
              <div class="oneContent flex-x">
                <div
                  style="
                    position: relative;
                    top: -7vh;
                    width: 1288px;
                    margin-left: -5px;
                  "
                >
                  <span class="carouselBlank" style="left: 0px">{{
                    col.title
                  }}</span>

                  <br /><br />

                  <span style="font-weight: 700; font-size: 17px">{{
                    col.remark
                  }}</span>

                  <br /><br /><br /><br />

                  <!-- <a style="color:none;" href="#" @click="$emit('childEvent', col.jumpUrl); return false;">
                    <img style="cursor: pointer;" src="../assets/images/zixun.png" />
                  </a> -->
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!-- 产品内容 -->
    <div class="divTwo flex" style="width: 100%; justify-content: center">
      <!-- 介于第一个div和第二个div之间的图层 -->
      <div class="divOAT" :style="'backgroundImage:url(' + divOATImg + ')'">
        <ul style="list-style: none">
          <!-- 遍历展示 -->
          <span v-for="divContent in divContents" :key="divContent.id">
            <li class="divOAT_li">
              <div class="divOAT_li_content float-right">
                <!-- 图层 -->
                <a
                  style="color: #191919"
                  href="#"
                  @click="
                    $emit('childEvent');
                    return false;
                  "
                >
                  <img
                    class="divOAT_li_content_img float-left"
                    :src="divContent.img"
                  />
                </a>
                <!-- 文字 -->
                <div class="divOAT_li_content_text">
                  <span class="li_content_text_blank">
                    <a
                      style="color: #191919"
                      href="#"
                      @click="
                        $emit('childEvent');
                        return false;
                      "
                    >
                      {{ divContent.blank }}
                    </a>
                  </span>
                  <br />
                  <span
                    style="
                      position: relative;
                      top: 5px;
                      font-weight: 500;
                      color: #8b8b8b;
                      font-size: 15px;
                    "
                  >
                    {{ divContent.text }}
                  </span>
                </div>
              </div>
            </li>
          </span>
        </ul>
      </div>

      <!-- 第二层自己的内容 -->
      <div class="divTwoSlef flex">
        <!-- 标题部分 -->
        <div class="divTwoBlank">
          <div class="twoBlank">安全、可信的产品服务</div>
          <div class="twoDes" style="margin-top: 15px">
            一站式财税服务&nbsp;&nbsp;&nbsp;
            <router-link to="/service">
              <img
                style="position: relative; top: 4px; cursor: pointer"
                src="../assets/images/chakansuoyouchanpin.png"
              />
            </router-link>
          </div>
        </div>

        <!-- 内容 -->
        <div class="divTwoContent">
          <el-container>
            <el-aside class="twoAside" width="322px">
              <el-menu
                class="el-menu-vertical-demo"
                background-color="rgba(0, 0, 0, 0)"
              >
                <!-- 遍历菜单 -->
                <span v-if="divTwoCnt == null">
                  <el-empty description="暂无数据"></el-empty>
                </span>
                <span v-else>
                  <span v-for="nav in divTwoCnt" :key="nav.id">
                    <span v-if="nav.pid == null">
                      <el-menu-item
                        class="twoAsideItem"
                        :class="{ activeItem: nav.id == divCntFlag }"
                        @mouseenter.native="showContent(nav.id)"
                      >
                        <span slot="title">{{ nav.title }}</span>
                      </el-menu-item>
                    </span>
                  </span>
                </span>
              </el-menu>
            </el-aside>
            <el-main style="padding: 0" class="twoMain">
              <span v-if="divTwoCnt == null">
                <el-empty description="暂无数据"></el-empty>
              </span>
              <span v-else>
                <ul style="list-style: none">
                  <span v-for="li in divTwoCnt" :key="li.id">
                    <span
                      v-if="
                        li.pid != null && li.pid == divCntFlag && li.isTop == 1
                      "
                    >
                      <li class="twoMainLi">
                        <el-popover
                          placement="right"
                          :title="li.title"
                          width="350"
                          trigger="click"
                          :content="li.productDesc"
                        >
                          <div
                            slot="reference"
                            style="
                              cursor: pointer;
                              font-family: PingFang SC bold;
                              position: relative;
                              left: 10%;
                              top: 21%;
                              width: 80%;
                              height: 60%;
                              text-align: left;
                            "
                          >
                            <span>{{ li.title }}</span>
                            <br /><br />
                            <p
                              style="
                                color: #696d76;
                                font-family: PingFang SC Medium;
                                height: 25px;
                                overflow: hidden;
                                line-height: 25px;
                                position: relative;
                                top: -5px;
                              "
                            >
                              {{ li.productDesc }}
                            </p>
                          </div>
                        </el-popover>
                      </li>
                    </span>
                  </span>
                </ul>
              </span>
            </el-main>
          </el-container>
        </div>
      </div>
    </div>

    <!-- 数据展示 -->

    <!-- 精彩瞬间 -->
    <div class="divFour flex">
      <!-- 标题部分 -->
      <div
        class="divTwoBlank"
        style="position: relative; top: 40px; color: #fff"
      >
        <div class="twoBlank" style="color: #fff">精彩瞬间</div>
        <div class="twoDes divFourDes">用专业的服务，为社会创造更大的价值</div>
      </div>
      <!-- 中间滚动展示图片 -->
      <div class="divFourCnt" style="width: 1920px; /* 防止换行 */">
        <el-carousel
          :interval="5000"
          type="card"
          height="420px"
          width="1200px"
          indicator-position="none"
        >
          <el-carousel-item v-for="item in divFour" :key="item.id">
            <img :src="item.content.img" style="width: 100%; height: 100%" />
            <h3 class="medium">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- 最后 -->
      <div
        class="divTwoBlank"
        style="position: absolute; color: rgb(255, 255, 255)"
      >
        <div
          class="twoDes"
          style="
            margin-top: 15px;
            position: relative;
            bottom: -580px;
            font-size: 13px;
          "
        >
          <a
            target="_blank"
            style="color: rgb(255, 255, 255)"
            href="https://mp.weixin.qq.com/s?__biz=MzkyMDI1MTg3Nw==&mid=2247502362&idx=1&sn=64f0c7bd266c49448ea379cf984e5439&chksm=c1973690f6e0bf86cdb5243e0f6c23a00dbbbb56eca901c97288be51f8bfbdef66f729a68bff&token=922743718&lang=zh_CN#rd"
          >
            <span style="cursor: pointer; position: relative; top: 10px"
              >查看更多精彩瞬间<img
                style="position: relative; top: 5px"
                src="../assets/images/more(3).png"
            /></span>
          </a>
        </div>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="divFive flex" style="100vw;">
      <!-- 标题部分 -->
      <div
        class="divTwoBlank"
        style="position: relative; top: 70px; color: #191919"
      >
        <div
          class="twoBlank twoBlankTitle"
          style="
            color: #191919;
            font-family: PingFang SC Bold;
            font-weight: 700;
            font-size: 34px;
          "
        >
          新闻资讯
        </div>
        <div
          class="twoDes"
          style="margin-top: 15px; font-size: 15px; color: #3c3c40"
        >
          聚焦新政，紧握机遇&nbsp;&nbsp;&nbsp;
          <router-link to="news">
            <img
              style="position: relative; top: 4px; cursor: pointer"
              src="../assets/images/chakansuoyouchanpin(1).png"
            />
          </router-link>
        </div>
      </div>
      <div class="divFiveCnt flex">
        <span v-for="item in newsType" :key="item.id">
          <span v-if="item.isTop == 1">
            <div class="FiveCntDiv">
              <div style="width=90%;margin-left: 30px;">
                <div
                  style="
                    position: relative;
                    font-size: 18px;
                    font-weight: 700;
                    margin-top: 40px;
                  "
                >
                  <!-- 新闻类型 -->
                  <p
                    style="
                      color: #191919;
                      font-family: PingFang SC Bold;
                      font-weight: 700;
                      font-size: 18px;
                    "
                  >
                    {{ item.typeName }}
                    <router-link to="news">
                      <span
                        style="
                          font-size: 14px;
                          color: #c4171e;
                          position: absolute;
                          right: 17px;
                          cursor: pointer;
                        "
                      >
                        查看更多&nbsp;<img
                          style="position: relative; top: 4px"
                          src="@/assets/images/more.png"
                        />
                      </span>
                    </router-link>
                  </p>
                  <!-- 新闻数据 -->
                  <div style="height: 140px; overflow: hidden">
                    <span v-for="li in news" :key="li.id">
                      <span v-if="li.typeId == item.id && li.isTop == true">
                        <div style="margin-top: 20px; font-size: 15px">
                          <div style="height: 20px">
                            <router-link
                              :to="{
                                name: 'newDetailsView',
                                params: { item: li },
                              }"
                            >
                              <el-tooltip
                                :content="li.title"
                                placement="right"
                                effect="light"
                              >
                                <p
                                  style="
                                    float: left;
                                    width: 68%;
                                    font-family: PingFang SC Medium;
                                    font-weight: 500;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    cursor: pointer;
                                    color: #454545;
                                  "
                                >
                                  · {{ li.title }}
                                </p>
                              </el-tooltip>
                            </router-link>

                            <span
                              style="
                                float: right;
                                color: #8b8b8b;
                                position: relative;
                                right: 20px;
                                font-family: PingFang SC Medium;
                                font-weight: 500;
                              "
                            >
                              {{ formatDate(li.date) }}
                            </span>
                          </div>
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </span>
      </div>
    </div>
    <!-- 底部图片 -->
    <div class="divSix flex">
      <div style="height: 200px; position: relative; top: 176px; width: 1288px">
        <span
          style="
            margin-top: 21px;
            font-size: 30px;
            font-weight: 700;
            color: #fff;
          "
        >
          资深团队提供专业、优质、可靠服务
        </span>
        <p style="margin-top: 40px; cursor: pointer; width: 200px">
          <a
            style="color: none"
            href="#"
            @click="
              $emit('childEvent');
              return false;
            "
          >
            <img src="@/assets/images/zixunW.png" />
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import parameterApi from "@/api/parameterApi.js";
import manageApi from "@/api/manageApi";
export default {
  name: "indexPage",
  data() {
    return {
      // 滚动图数据
      carousels: [
        {
          id: 1,
          imgUrl: require("../assets/images/1.png"),
          blank: "嘉信瑞，第一批3A税务师事务所 ",
          text: "专业从事财税行业30年，助力企业更好的未来",
        },
        {
          id: 2,
          imgUrl: require("../assets/images/1.png"),
          blank: "财税一站式服务，省事省力更省心",
          text: "专业从事财税行业30年，助力企业更好的未来",
        },
        {
          id: 3,
          imgUrl: require("../assets/images/1.png"),
          blank: "专业顾问一对一服务，您的专属财税管家 ",
          text: "专业从事财税行业30年，助力企业更好的未来",
        },
        {
          id: 4,
          imgUrl: require("../assets/images/1.png"),
          blank: "服务过上万家企业，一路为您护航保驾 ",
          text: "专业从事财税行业30年，助力企业更好的未来",
        },
        {
          id: 5,
          imgUrl: require("../assets/images/1.png"),
          blank: "个性化服务，量身定制专属方案 ",
          text: "专业从事财税行业30年，助力企业更好的未来",
        },
      ],
      // 中间层图片
      divOATImg: require("../assets/images/矩形 546.png"),
      // 中间层内容
      divContents: [
        {
          id: 1,
          img: require("../assets/images/咨询.png"),
          blank: "免费咨询 ",
          text: "专业客服为你解答财税问题",
        },
        {
          id: 2,
          img: require("../assets/images/专业人士.png"),
          blank: "专业人士 ",
          text: "1v1在线解答问题",
        },
        {
          id: 3,
          img: require("../assets/images/icon-03.png"),
          blank: "安全可靠 ",
          text: "统一服务标准严格保密",
        },
        {
          id: 4,
          img: require("../assets/images/icon-04.png"),
          blank: "实力保障 ",
          text: "16年财税行业经验",
        },
      ],
      // 第二层内容
      divTwoCnt: null,
      // 一个标记,标记哪个标签需要展示
      divCntFlag: null,
      // 标记,鼠标移除时,展示当前选中
      divCntOldFlag: 1,
      divTre: [
        {
          id: 1,
          num: 13,
          title: "办公室",
        },
        {
          id: 2,
          num: 16,
          title: "合作伙伴",
        },
        {
          id: 3,
          num: 17,
          title: "行业经验",
        },
        {
          id: 4,
          num: 100,
          title: "专业老师",
        },
      ],
      divFour: [
        {
          id: 1,
          content: {
            title: "2019年度深圳税协工作总结会",
            img: require("../assets/images/2023pxh1.jpg"),
            text: "暨税梦启航基金慈善拍卖会",
            date: "2019.8.29",
          },
        },
        {
          id: 2,
          content: {
            title: "2021年度最新财税法规培训会圆满举办!",
            img: require("../assets/images/2023pxh2.jpg"),
            text: "2021年11月26日，深圳皇嘉财润财务顾问股份有限公司主办的2021年度最新财税法规培训会在深圳福田成功召开。本次培训会，聚焦2021年度财税新政，剖析企业在发展管理过程中的痛点和难点，针对性地提出有效的建议，为企业搭建高效的交流平台。",
            date: "2021.11.29",
          },
        },
        {
          id: 3,
          content: {
            title: "2022年度最新财税法规培训圆满收官！",
            img: require("../assets/images/2023pxh3.jpg"),
            text: "皇嘉财润一直致力打造会计行业品牌，在全国布局17家分子公司，全国连锁的服务网络使得公司能够全方位调动有敬业精神、具有丰富当地经验及深厚行业知识的专业团队为企业提供财税一站式的专业服务，帮助客户顺利经营、稳健发展、勇攀高峰！",
            date: "2022.12.01",
          },
        },

        // {
        //   id: 4,
        //   content: {
        //     title: "2023年度最新财税法规培训会圆满举办!",
        //     img: require("../assets/images/2023pxh.jpg"),
        //     text: "2023年度最新财税法规培训会",
        //     date: "2023.8.29",
        //   },
        // },
      ],
      showFourImg: 2,
      //新闻类型
      newsType: null,
      // 新闻数据
      news: null,
      new: {
        newsType: [],
        news: [],
      },
      str: [
        {
          id: 1,
          content: 1,
        },
        {
          id: 2,
          content: 2,
        },
        {
          id: 3,
          content: 3,
        },
        {
          id: 4,
          content: 4,
        },
      ],
      imgs: [],
      slideStop: false,
      btnRight: 15,
      leftBtn: 15,
      left: 65.88,
      navLeft: 0,
      oatWidth: 100,
      textLeft: 6,
    };
  },
  // created() {
  //   window.addEventListener("resize", this.getWidth);
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this.getWidth);
  // },
  mounted() {
    this.getData();
    // 改变滚动图切换按钮的样式
    // this.$nextTick(() => {
    //   const carouselEl = this.$refs.myCarousel.$el; // 获取 Carousel 组件的 DOM 元素
    //   const indicatorsEl = carouselEl.querySelector(".el-carousel__indicators"); // 获取指示器的 DOM 元素
    //   const Btns = carouselEl.querySelectorAll(".el-carousel__button"); // 获取指示器的 DOM 元素
    //   // 在这里使用 JavaScript 调整指示器的位置
    //   indicatorsEl.style.cssText = "width:1300px;bottom:120px;";
    //   Btns.forEach((button) => {
    //     button.style.height = "4px";
    //     button.style.width = "35px";
    //     button.style.borderRadius = "1px";
    //     button.style.backgroundColor = "white";
    //     button.style.opacity = "1";
    //   });
    // });
    // const slides = document.querySelector(".slides");
    // this.imgs = slides.querySelectorAll("img");
    // this.startSlideshow(); // 在组件加载后启动幻灯片
  },
  methods: {
    // getWidth() {
    //   const width = window.innerWidth;
    //   console.log(width);
    //   if (width >= 1820) {
    //     this.oatWidth = 100;
    //     this.btnRight = 13;
    //     this.leftBtn = 13;
    //     this.navLeft = 16.25;
    //     this.textLeft = 6;
    //     this.left = 65.88;
    //     const carouselEl = this.$refs.myCarousel.$el; // 获取 Carousel 组件的 DOM 元素
    //     const indicatorsEl = carouselEl.querySelector(
    //       ".el-carousel__indicators"
    //     ); // 获取指示器的 DOM 元素
    //     console.log(indicatorsEl);
    //     // 在这里使用 JavaScript 调整指示器的位置
    //     indicatorsEl.style.cssText =
    //       "width:100%;left:" + this.left + "%;bottom:120px;";
    //   } else if (width > 1440 && width < 1820) {
    //     this.oatWidth = 90;
    //     this.btnRight = 15;
    //     this.textLeft = 1;
    //     this.leftBtn = 15;
    //     this.navLeft = 11.25;
    //     this.left = 61;
    //     const carouselEl = this.$refs.myCarousel.$el; // 获取 Carousel 组件的 DOM 元素
    //     const indicatorsEl = carouselEl.querySelector(
    //       ".el-carousel__indicators"
    //     ); // 获取指示器的 DOM 元素
    //     console.log(indicatorsEl);
    //     // 在这里使用 JavaScript 调整指示器的位置
    //     indicatorsEl.style.cssText =
    //       "width:100%;left:" + this.left + "%;bottom:120px;";
    //   } else {
    //     this.oatWidth = 100;
    //     this.btnRight = 18;
    //     this.leftBtn = 18;
    //     this.navLeft = 5;
    //     this.textLeft = -5;
    //     this.left = 55;
    //     const carouselEl = this.$refs.myCarousel.$el; // 获取 Carousel 组件的 DOM 元素
    //     const indicatorsEl = carouselEl.querySelector(
    //       ".el-carousel__indicators"
    //     ); // 获取指示器的 DOM 元素
    //     console.log(indicatorsEl);
    //     // 在这里使用 JavaScript 调整指示器的位置
    //     indicatorsEl.style.cssText =
    //       "width:100%;left:" + this.left + "%;bottom:120px;";
    //   }
    // },
    getData() {
      this.getSlide();
      // 获取产品
      this.getList();
      // 获取新闻
      this.getNews();
    },
    //获取轮播图数据
    getSlide() {
      manageApi
        .getSlide()
        .then((response) => {
          this.carousels = response.data.obj;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //精彩瞬间幻灯片
    // nextPto() {
    //   if (this.slideStop) {
    //     return;
    //   }
    //   this.stop();
    //   const imgs = this.imgs;
    //   let imgPx = 0;
    //   let flagZero = 0;
    //   //滚动
    //   const a = setInterval(() => {
    //     if (imgPx < 965) {
    //       imgPx += 32;
    //       //第一张图
    //       if (imgPx < 400) {
    //         imgs[0].style.left = -imgPx + "px";
    //       } else {
    //         if (flagZero == 0) {
    //           flagZero += 1;
    //           imgs[0].style.left = "105vw";
    //           let zeroPx = 2000;
    //           const b = setInterval(() => {
    //             if (zeroPx > 1585) {
    //               zeroPx -= 15;
    //               imgs[0].style.left = zeroPx + "px";
    //             } else {
    //               clearInterval(b);
    //             }
    //           }, 10);
    //         }
    //       }
    //       //第二张图
    //       imgs[1].style.right = imgPx + "px";
    //       //第三张图
    //       if (imgPx < 622) {
    //         imgs[2].style.right = imgPx + "px";
    //       }
    //     } else {
    //       clearInterval(a);
    //       imgs[1].style.right = "0px";

    //       imgs[0].style.left = "0px";
    //       setTimeout(() => {
    //         imgs[2].style.right = "0px";
    //       }, 31);
    //       // 数组长度
    //       const fLen = this.divFour.length;
    //       //保留数组第一个,因为从0开始,会将数组下标为0的值丢失;
    //       let temp = this.divFour[0].content;
    //       this.divFour.forEach((element) => {
    //         const i = element.id;
    //         //三目运算符,如果i等于数组长度,则将数组第一个数据赋值给下表为i-1的元素,否则用下表i的元素赋值
    //         this.divFour[i - 1].content =
    //           i == fLen ? temp : this.divFour[i].content;
    //       });
    //     }
    //   }, 20);
    // },
    // 切换上一张
    // prevPto() {
    //   if (this.slideStop) {
    //     return;
    //   }
    //   this.stop();
    //   const slides = document.querySelector(".slides");
    //   const imgs = slides.querySelectorAll("img");
    //   let imgPx = 0;
    //   let flagZero = 0;

    //   const a = setInterval(() => {
    //     if (imgPx < 960) {
    //       imgPx += 32;
    //       imgs[0].style.left = imgPx + "px";
    //       //第二张图
    //       if (imgPx < 622) {
    //         imgs[1].style.right = -imgPx + "px";
    //       }
    //       //第三张图
    //       if (imgPx < 400) {
    //         imgs[2].style.right = -imgPx + "px";
    //       } else {
    //         if (flagZero == 0) {
    //           flagZero += 1;
    //           imgs[2].style.right = "105vw";
    //           let zeroPx = 2000;
    //           const b = setInterval(() => {
    //             if (zeroPx > 1585) {
    //               zeroPx -= 15;
    //               imgs[2].style.right = zeroPx + "px";
    //             } else {
    //               clearInterval(b);
    //             }
    //           }, 10);
    //         }
    //       }
    //     } else {
    //       clearInterval(a);
    //       setTimeout(() => {
    //         imgs[1].style.right = "0px";
    //       }, 31);
    //       imgs[0].style.left = "0px";
    //       imgs[2].style.right = "0px";
    //       //数组长度
    //       const fLen = this.divFour.length;
    //       //保留数组最后一个
    //       let temp = this.divFour[fLen - 1].content;
    //       //倒序遍历
    //       for (let i = fLen - 1; i >= 0; i--) {
    //         this.divFour[i].content =
    //           i == 0 ? temp : this.divFour[i - 1].content;
    //       }
    //     }
    //   }, 20);
    // },
    // stop() {
    //   this.slideStop = true;
    //   setTimeout(() => {
    //     this.slideStop = false;
    //   }, 1000);
    // },
    //幻灯片
    // startSlideshow() {
    //   this.timer = setInterval(() => {
    //     this.nextPto();
    //   }, 3000); // 每3秒触发一次nextPto方
    // },
    mouseenter() {
      clearInterval(this.timer);
      const query = document.querySelector(".divFourCenterText");
      const query1 = document.querySelector(".divFourCenter");
      query.style.zIndex = 10;
      query1.style.opacity = 0.7;
      query.style.opacity = 1;
    },
    mouseleave() {
      const query = document.querySelector(".divFourCenterText");
      const query1 = document.querySelector(".divFourCenter");
      query.style.zIndex = 0;
      query1.style.opacity = 1;
      query.style.opacity = 0.5;
      this.startSlideshow();
    },
    handleMouseEnter() {
      clearInterval(this.timer);
    },
    handleMouseLeave() {
      this.startSlideshow(undefined);
    },
    beforeDestroy() {
      console.log("清楚计时器");
      // 在组件销毁之前清除计时器，以防止内存泄漏
      clearInterval(this.timer);
    },
    showContent(id) {
      this.divCntFlag = id;
    },
    // 获取产品信息
    getList() {
      parameterApi
        .getProduct()
        .then((response) => {
          this.divTwoCnt = response.data.obj;
          this.$store.commit("setProduct", this.divTwoCnt);
          const divTwoCnt = response.data.obj[0].id;
          this.showContent(divTwoCnt);
          this.divCntFlag = response.data.obj[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取新闻
    getNews() {
      parameterApi
        .getNewsType()
        .then((response) => {
          this.newsType = response.data.obj;
          this.$store.commit("setNewsType", this.newsType);
        })
        .catch((err) => {
          console.log(err);
        });
      parameterApi
        .getNews()
        .then((response) => {
          this.news = response.data.obj;
          this.$store.commit("setNews", this.news);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 处理日期
    formatDate(datetimeString) {
      const date = new Date(datetimeString);
      // 获取年份
      const year = date.getFullYear();
      // 获取月份,从0开始,所以＋1,如果不足2位,补0
      const month = String(date.getMonth() + 1).padStart(2, "0");
      // 获取日期 ,如果不足2位,补0
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}.${month}.${day}`;
    },
  },
};
</script>

<style scoped>
@import "../assets/css/default.css";
@import "../assets/css/index.css";

.el-carousel__item:nth-child(2n) {
  position: absolute;
  left: 0% !important;
}

.el-carousel__item:nth-child(2n + 1) {
  position: absolute;
  /* background-color: #d3dce6; */
}

.divFour {
  position: relative;
  height: 666px;
  background-image: url("../assets/images/jingcai.png");
  background-size: cover;
}

.divFourCnt {
  position: absolute;
  top: 160px;
  height: 304px;
  width: 100vw;
}

.Fourbtn {
  position: absolute;
  top: 45%;
  z-index: 10;
  cursor: pointer;
}

.fourcntImg {
  position: absolute;
  width: 100vw;
  height: 304px;
}

.fourcntImg img {
  position: relative;
  width: 537px;
  height: 304px;
  margin-left: 70px;
}
::v-deep .el-carousel__indicator.is-active button {
  background-color: red !important;
}

.divFourDes {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 200;
  font-size: 15px;
  color: #fff;
}
.divFourCenter {
  width: 537px;
  height: 304px;
  position: relative;
  left: 340px;
  transition: 1s;
}
.slides img {
  position: relative;
}
.divFourCenterText {
  width: 424px;
  height: 340px;
  background: #fff;
  position: absolute;
  top: -18px;
  margin-left: -20px;
  opacity: 0.5;
  transition: 1s;
}
.divFourCenterOne {
  margin: 25px;
  font-family: PingFang SC Bold;
  font-weight: 700;
  font-size: 17px;
  color: #191919;
}
.divFourCenterTwo {
  margin: 25px;
  width: 67%;
  height: 180px;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #454545;
}
.divFourCenterThree {
  margin: 25px;
  font-family: PingFang SC Medium;
  font-weight: 500;
  font-size: 14px;
  color: #696d76;
}
.divFourLeft {
  position: relative;
  left: -90px;
  width: 537px;
  height: 304px;
}
/deep/.el-carousel__item--card.is-active {
  position: relative;
  width: 800px;
  left: 90px !important;
}
</style>
