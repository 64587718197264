var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customPage"},[_c('buttonView',{on:{"childEvent":function($event){return _vm.$emit('childEvent')}}}),_vm._m(0),_c('div',{staticClass:"elMain flex"},[_c('div',{staticClass:"elMainCnt",staticStyle:{"overflow":"hidden"}},[_c('p',{staticStyle:{"font-family":"PingFang SC Bold","font-weight":"700","font-size":"34px","text-align":"center"}},[_vm._v(" 客户案例 ")]),_c('br'),_c('el-tabs',{attrs:{"stretch":true},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.eltab),function(tab){return _c('el-tab-pane',{key:tab.id,attrs:{"label":tab.label,"name":tab.name}},[_c('br'),_c('ul',{staticStyle:{"list-style":"none"}},_vm._l((tab.data),function(o){return _c('span',{key:o.id},[(o.id % 4 != 0)?_c('span',[_c('li',{staticClass:"float-left elTab",staticStyle:{"margin-left":"1px","margin-right":"28px"}},[_c('img',{staticStyle:{"position":"relative"},attrs:{"src":o.img}}),_c('p',{staticClass:"tabText"},[_vm._v(" "+_vm._s(o.title)+" ")]),_c('br'),_c('p',{staticClass:"tabText tabDes"},[_vm._v(" "+_vm._s(o.des)+" ")])])]):_c('span',[_c('li',{staticClass:"float-left elTab",staticStyle:{"margin-right":"1px"}},[_c('img',{staticStyle:{"position":"relative"},attrs:{"src":o.img}}),_c('p',{staticClass:"tabText"},[(
                        o.title == '深圳市人力资源和社会保障局罗湖分局' ||
                        o.title == '海德(广州)国际物流集团有限公司上海分公司'
                      )?_c('span',{style:(o.title == '深圳市人力资源和社会保障局罗湖分局'
                          ? 'font-size: 15px'
                          : 'font-size:13px')},[_vm._v(" "+_vm._s(o.title)+" ")]):_c('span',[_vm._v(" "+_vm._s(o.title)+" ")])]),_c('br'),_c('p',{staticClass:"tabText tabDes"},[_vm._v(" "+_vm._s(o.des)+" ")])])])])}),0)])}),1),_c('div',{staticStyle:{"height":"70px"}})],1)]),_c('div',{staticClass:"elMain flex",staticStyle:{"background-color":"#f7f7f7"}},[_c('div',{staticClass:"elMainCnt"},[_c('p',{staticStyle:{"font-family":"PingFang SC Bold","font-weight":"700","font-size":"34px","text-align":"center"}},[_vm._v(" 优秀合作伙伴 ")]),_c('br'),_c('br'),_c('ul',{staticStyle:{"list-style":"none","overflow":"hidden"}},_vm._l((_vm.images),function(o){return _c('span',{key:o.id},[(o.id % 5 != 0)?_c('span',[_c('li',{staticClass:"float-left",staticStyle:{"margin-right":"50px","margin-bottom":"50px"}},[_c('img',{attrs:{"src":o.img}})])]):_c('span',[_c('li',{staticClass:"float-left"},[_c('img',{attrs:{"src":o.img}})])])])}),0),_c('div',{staticStyle:{"height":"100px"}})])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"HeaderBG"},[_c('img',{staticStyle:{"width":"100vw","height":"430px"},attrs:{"src":require("@/assets/images/110000.png")}}),_c('div',{staticClass:"flex",staticStyle:{"color":"#191919"}},[_c('div',{staticStyle:{"position":"absolute","width":"1290px","top":"210px"}},[_c('span',{staticClass:"carouselBlank"},[_vm._v("成功案例")]),_c('br'),_c('br'),_c('span',{staticClass:"carouselDes"},[_vm._v("服务过上万家企业，品牌值得信赖！")]),_c('br'),_c('br')])])])
}]

export { render, staticRenderFns }