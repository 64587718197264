<template>
    <div calss="btnPage">
        <!-- 咨询按钮,固定 -->
        <div style="position: fixed; z-index: 100;cursor: pointer;right:50px;top:500px">
            <a style="color:none;" href="#" @click="$emit('childEvent'); return false;">
                <img style="border-radius: 50%;" src="@/assets/images/ljzx.png">

                <div style="height: 27px;width: 73px;background: #fff;position: relative;top: -18px;left:2.5px;">
                    <p
                        style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
            font-family: PingFang SC Medium;font-weight: 500;font-size: 15px;text-align: left;color: #c4171e;line-height: 28px;">
                        <span style="padding: 5.5px;">立即咨询</span>
                    </p>
                </div>
            </a>
        </div>

    </div>
</template>

<script>
export default {
    name: 'btnPage',

}
</script>