import layout from '../layout/layout'
import aboutMeView from '../views/aboutMe/aboutMeView'
import contactMeView from '../views/contactMe/contactMeView'
import workView from '../views/work/workView'
import serviceView from '../views/service/serviceView'
import customView from '../views/custom/customView'
import newsView from '../views/news/newsView'
import indexView from '../views/indexView'
import financeView from '../views/finance/financeView'
import newDetailsView from '../views/news/newChild/newDetailsView'
import buttonView from '../components/buttonView'

export default [
    {
        path: '/',
        name: 'layout',
        component: layout,
        meta: {
            title: '深圳市嘉信瑞税务事务所有限公司'
        },
        children: [
            {
                path: '/',
                name: 'index',
                component: indexView,
                meta: {
                    title: '深圳市嘉信瑞税务事务所有限公司'
                },
            },
            {
                path: '/aboutMe',
                name: 'aboutMe',
                component: aboutMeView,
                meta: {
                    title: '关于我们'
                },
            },
            {
                path: '/contactMe',
                name: 'contactMe',
                component: contactMeView,
                meta: {
                    title: '联系我们'
                },
            },
            {
                path: '/custom',
                name: 'custom',
                component: customView,
                meta: {
                    title: '客户案例'
                },
            },
            {
                path: '/news',
                name: 'news',
                component: newsView,
                meta: {
                    title: '新闻资讯'
                },
            },
            {
                path: '/service',
                name: 'service',
                component: serviceView,
                meta: {
                    title: '服务范围'
                },
            },
            {
                path: '/finance',
                name: 'finance',
                component: financeView,
                meta: {
                    title: '年度财务法规'
                },
            },
            {
                path: '/work',
                name: 'work',
                component: workView,
                meta: {
                    title: '党建工作'
                },
            },
            {
                path: '/newDetailsView',
                name: 'newDetailsView',
                component: newDetailsView,
                meta: {
                    title: '详情'
                },

            },
            {
                path: '/btn',
                name: 'btnView',
                component: buttonView,
                meta: {
                    title: '详情'
                },
            },
        ]
    },
    {
        path: '/error',
        name: 'errorView',
        component: () => import('@/views/errorView')
    }
]