<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@font-face {
  font-family: "PingFang SC bold";
  src: url("@/assets/font/PingFang Bold.ttf");
}
#app {
  padding: 0;
  margin: 0;
  font-family: "PingFang SC Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #191919;
}
/* 添加滚动条样式 */
/* Webkit浏览器（如Chrome、Safari） */
::-webkit-scrollbar {
  width: 10px; /* 设置滚动条宽度 */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(235, 93, 93); /* 滚动条滑块的颜色 */
  border-radius: 4px; /* 滚动条滑块的圆角 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(236, 32, 32); /* 悬停时滚动条滑块的颜色 */
}
</style>
