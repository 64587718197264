<template>
  <div class="financePage">

    <div class="HeaderBG">
      <img style="width: 100vw;height: 430px;" src="@/assets/images/VCG211183404957.png" />

      <!-- 背景图内容 -->
      <div class="flex" style="color:#191919">

        <div style="position: absolute;width:1290px;top: 210px;">

          <span class="carouselBlank">年度财税法规</span>

          <br /><br />

        </div>
      </div>

    </div>


    <div class="elMain">
      <div class="elMain flex" style="width: 100%;background: #F7F7F7;">
        <div class="elMainCnt" style="margin-top: 70px;position: relative;">
          <div style="width: 1290px;">
            <span v-for="o in works" :key="o.id">
              <div class="workDes">
                <p style="width: 70%;text-overflow: ellipsis;overflow: hidden;white-space:nowrap;">·{{ o.workTitle }}</p>
                <span class="workDate">
                  {{ o.date }}
                </span>
              </div>
            </span>
          </div>

          <div class="flex" style="position: relative;margin-top: 91px;">
            <span>
              <el-button @click="goPrev()" :disabled="prev" style="padding:8px;width: 93px;"><img
                  src="@/assets/images/arrow-down(3).png" style="position: relative;top: 2px;">&nbsp;&nbsp;上一页</el-button>
            </span>

            <el-pagination @current-change="handleCurrentChange" background :hide-on-single-page="true"
              :current-page="pageNum" :page-size=pageSize layout="pager" :total="total">
            </el-pagination>
            <span><el-button :disabled="next" style="padding:8px;width: 93px;" @click="goNext()"> 下一页&nbsp;&nbsp;<img
                  src="@/assets/images/arrow-down.png" style="position: relative;top: 2px;"></el-button></span>

            <span style="position: relative;left: 15px;top: 5px;font-size: 15px;">
              <span style="color:#C4171E">{{ pageNum }}</span>/{{ Math.ceil(total / pageSize) }}
            </span>

            <span style="position: relative;left: 30px;top: -3px;">
              <span style="color:#8B8B8B;font-family: PingFang SC Medium;font-weight: 500;font-size: 15px;">到第</span>
              <el-input type="text" v-model="search"
                style="width: 48px;margin-left: 15px;margin-right: 15px;padding: 0px;"></el-input>
              <span style="color:#8B8B8B;font-family: PingFang SC Medium;font-weight: 500;font-size: 15px;">页</span>

              <el-button @click="searchPage(search)"
                style="padding:8px;width:53px;color: #191919;margin-left: 15px;">确定</el-button>
            </span>
          </div>

          <div style="height:150px;">

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
export default {
  name: 'financePage',
  data() {
    return {
      currentPage: 1,
      pageNum: 1,
      pageSize: 8,
      total: 304,
      prev: null,
      next: null,
      search: null,
      works: [
        {
          id: 1,
          workTitle: '文字文字文字文字文字',
          date: '2023-01-01  15:40'
        },
        {
          id: 2,
          workTitle: '文字文字文字文字文字文字',
          date: '2023-01-01  15:40'
        },
        {
          id: 3,
          workTitle: '文字文字文字文字文字文字文字',
          date: '2023-01-01  15:40'
        },
        {
          id: 4,
          workTitle: '文字文字文字文字文字',
          date: '2023-01-01  15:40'
        },
        {
          id: 5,
          workTitle: '文字文字文字文字文字文字文字文字',
          date: '2023-01-01  15:40'
        },
        {
          id: 6,
          workTitle: '文字文字文字文字文字文字文字',
          date: '2023-01-01  15:40'
        },
        {
          id: 7,
          workTitle: '文字文字文字文字文字文字文字文字文字',
          date: '2023-01-01  15:40'
        },
        {
          id: 8,
          workTitle: '文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字字文字文字文字',
          date: '2023-01-01  15:40'
        }
      ],
    }
  },
  mounted() {
    this.$router.push('/'); 
    this.handleCurrentChange(this.pageNum);
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleCurrentChange(val) {
      this.next = this.total / this.pageSize == val;
      this.prev = val == 1;
      this.pageNum = val;
      console.log(`从数据库查找第: ${val}` + '页');
    },
    searchPage(page) {
      if(page<1){
        this.$message({
          message: '请输入大于0的数',
          type: 'info',
          duration: 1000
        })
        return;
      }
      page = parseInt(page);
      const msg = isNaN(page) ? '请输入数字' : Math.ceil(this.total / this.pageSize) < page ? '超过最大页数' : null;
      console.log(msg);

      if (msg != null) {
        this.$message({
          message: msg,
          type: 'info',
          duration: 1000
        })
        return;
      }

      const number = parseInt(page);
      this.handleCurrentChange(number);
      this.search = null;
    },
    goNext() {
      this.handleCurrentChange(this.pageNum + 1);
    },
    goPrev() {
      this.handleCurrentChange(this.pageNum - 1);
    }
  }
}
</script>
  
<style scoped>
@import url('../../assets/css/default.css');

.workDes {
  height: 46px;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 25px;
  font-family: PingFang SC Bold;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: #191919;
}

.workDate {
  position: relative;
  right: 10px;
  top: -20px;
  float: right;
  font-family: PingFang SC Medium;
  font-weight: 500;
  font-size: 16px;
  color: #454545;
}

::v-deep .el-pagination.is-background .el-pager li {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ff2832;
}
</style>
  

