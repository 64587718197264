import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);


const store = new Vuex.Store({
    state: {
        // 在这里定义您的状态
        param: null,
        twoCnt:null,
        product:1,
        news:null,
        newsType:null,
        newData:null,
        ditu:null,
        slides:null,
        oldType:null,
    },
    mutations: {
        // 在这里定义您的变更函数
        setSlides(state,data){
            state.slides = data;
        },
        setParam(state, data) {
            state.param = data;
        },
        setTwoCnt(state, data) {
            state.twoCnt = data;
        },
        setProduct(state, data) {
            state.product = data;
        },
        setNews(state,data){
            state.news = data;
        },
        setNewsType(state,data){
            state.newsType = data;
        },
        setOldType(state,data){
            state.oldType = data;
        },
        setNewData(state,data){
            state.newData = data;
        },
        setDitu(state,data){
            state.ditu = data;
        },
        clearDitu(state){
            state.ditu = null;
        }
    },
    actions: {
        // 在这里定义您的动作
    },
    getters: {
        // 在这里定义您的获取函数
        getParam(state) {
            return state.serverData;
        }
    }
});

// 在浏览器刷新前保存数据到 sessionStorage
window.addEventListener('beforeunload', () => {
    const stateJson = JSON.stringify(store.state);
    sessionStorage.setItem('vuexState', stateJson);
  });
  
  // 在 Vue 实例初始化前，检查是否有之前保存的数据
  const storedStateJson = sessionStorage.getItem('vuexState');
  if (storedStateJson) {
    const storedState = JSON.parse(storedStateJson);
    store.replaceState(storedState);
  }

export default store;
