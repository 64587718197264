import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import store from './store'; // 导入 Vuex Store

Vue.use(ElementUI);

// 在Vue实例之前设置全局默认位置
Vue.prototype.$message = function (options) {
  ElementUI.Message({
    ...options,
    offset:200,
    duration:1500,
  });
};


new Vue({
  router,
  store, // 将 Vuex Store 注入到 Vue 实例中
  el: '#app',
  render: h => h(App)
}).$mount('#app');
