import request from "@/request"
export default {
    login(data) {
        return request.post('/user/login', data)
    },
    getToken() {
        return request.get('/news/token')
    },
    getSlide() {
        return request.get('/rotation-chart/getChart')
    },
    updateSlide(data) {
        return request.post('/rotation-chart/modifyOne', data)
    },
    getAllNews(data) {
        return request.get('/news/searchList', data)
    },
    addNews(data) {
        return request.post('/news/addNews', data)
    },
    delNews(data) {
        return request.delete('/news/removeOne?id=' + data)
    },
    updateNews(data) {
        return request.post('/news/modifyNews', data)
    },
    addNewCnt(data) {
        return request.post('/news/newsContentSave', data)
    },
    aboutMeUpdate(content, id) {
        return request.post('/about-us/edit', { content: content, id: id })
    },
    updatePro(data) {
        return request.post('/product/modify', data)
    },
    addPro(data) {
        return request.post('/product/addProduct', data)
    },
    addProType(data) {
        return request.post('/product/addProductType?typeName=' + data)
    },
    delPro(data) {
        return request.delete('/product/removeProduct', data)
    },
    delProType(data) {
        return request.delete('/product/removeType?ptitle=' + data)
    },
    delWork(data) {
        return request.delete('/party-building-news/del?id=' + data)
    },
    updateWork(data) {
        return request.post('/party-building-news/modify', data)
    },
    addWork(data) {
        return request.post('/party-building-news/insertNew', data)
    }

}