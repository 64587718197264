
export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/manage/loginView'), // 懒加载组件
        meta: {
            title: '嘉信瑞后台管理登陆'
        }
    },
    // 可以添加其他路由规则
    {
        path: '/manage',
        name: 'manage',
        component: () => import('@/views/manage/manageView'), // 懒加载组件
        meta: {
            title: '嘉信瑞后台管理登陆'
        },
        children:[
            {
                path:'/indexMg',
                name:'indexMg',
                component:() => import('@/views/manage/manageChild/indexMgView')
            },
            {
                path:'/aboutMg',
                name:'aboutMg',
                component:() => import('@/views/manage/manageChild/aboutMgView')
            },
            {
                path:'/serviceMg',
                name:'serviceMg',
                component:() => import('@/views/manage/manageChild/serviceMgView')
            },
            {
                path:'/newsMg',
                name:'newsMg',
                component:() => import('@/views/manage/manageChild/newsMgView')
            },
            {
                path:'/customMg',
                name:'customMg',
                component:() => import('@/views/manage/manageChild/customMgView')
            },
            {
                path:'/workMg',
                name:'workMg',
                component:() => import('@/views/manage/manageChild/workMgView')
            },
            {
                path:'/financeMg',
                name:'financeMg',
                component:() => import('@/views/manage/manageChild/financeMgView')
            },
            {
                path:'/contactMg',
                name:'contactMg',
                component:() => import('@/views/manage/manageChild/contactMgView')
            }
        ]
    }
];
