<template>
  <div
    class="layoutPage"
    style="
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      min-width: 1288px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      z-index: 1;
    "
  >
    <el-container>
      <el-header
        class="layHeader flex"
        height="80px"
        style="position: fixed; display: flex; justify-content: center"
      >
        <div
          style="width: 100%; max-width: 1288px; overflow: hidden; height: 80px"
          :style="'margin-left:' + navLeft + 'px'"
        >
          <!-- 左边图片 -->
          <router-link to="/" style="text-decoration: none">
            <div class="float-left" :style="'left:' + imgLeft + '%'">
              <img src="../assets/images/logo.png" />
            </div>
          </router-link>

          <!-- 右边导航栏 -->
          <div class="header-nav float-right">
            <!-- 根据窗口大小来控制导航栏 -->
            <span v-if="width < 1350" style="position: fixed; right: 2%">
              <el-dropdown>
                <el-button type="primary" style="background: red; border: none">
                  <i class="el-icon-s-fold el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <span v-for="nav in nav" :key="nav.id">
                    <span v-if="nav.pid == null && nav.titleEng != null">
                      <span
                        v-if="nav.titleEng != ' finance'"
                        style="color: #191919"
                      >
                        <router-link
                          :to="nav.titleEng.trim()"
                          style="text-decoration: none"
                        >
                          <el-dropdown-item>
                            {{ nav.title }}
                          </el-dropdown-item>
                        </router-link>
                      </span>
                      <!-- 如果是财税法规,需要跳转到其他链接 -->
                      <span v-else>
                        <a
                          target="_blank"
                          style="color: #191919"
                          href="https://mp.weixin.qq.com/s?__biz=MzkyMDI1MTg3Nw==&mid=2247502362&idx=1&sn=64f0c7bd266c49448ea379cf984e5439&chksm=c1973690f6e0bf86cdb5243e0f6c23a00dbbbb56eca901c97288be51f8bfbdef66f729a68bff&token=922743718&lang=zh_CN#rd"
                        >
                          <el-dropdown-item>
                            {{ nav.title }}
                          </el-dropdown-item>
                        </a>
                      </span>
                    </span>
                  </span>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <span v-else>
              <el-menu
                class="el-menu-demo"
                mode="horizontal"
                text-color="#191919"
                active-text-color="none"
                background-color="rgba(0, 0, 0, 0)"
                router
              >
                <!-- 如果数据为空,展示默认 -->
                <span v-if="nav == null">
                  <el-menu-item class="header-item float-left" index="aboutme"
                    >关于我们</el-menu-item
                  >
                  <el-menu-item class="header-item float-left" index="service"
                    >服务范围</el-menu-item
                  >
                  <el-menu-item class="header-item float-left" index="news"
                    >新闻资讯</el-menu-item
                  >
                  <el-menu-item class="header-item float-left" index="custom"
                    >客户案例</el-menu-item
                  >
                  <el-menu-item class="header-item float-left" index="work"
                    >党建工作</el-menu-item
                  >
                  <el-menu-item class="header-item float-left" index="finance"
                    >年度财务法规</el-menu-item
                  >
                  <el-menu-item class="header-item float-left" index="contactme"
                    >联系我们</el-menu-item
                  >
                </span>
                <span v-else>
                  <!-- 筛选导航栏 -->
                  <span v-for="nav in nav" :key="nav.id">
                    <span v-if="nav.pid == null && nav.titleEng != null">
                      <span v-if="nav.titleEng != ' finance'">
                        <el-menu-item
                          class="header-item float-left"
                          :index="nav.titleEng.trim()"
                          >{{ nav.title }}</el-menu-item
                        >
                      </span>
                      <span v-else>
                        <a
                          target="_blank"
                          style="color: rgb(255, 255, 255)"
                          href="https://mp.weixin.qq.com/s?__biz=MzkyMDI1MTg3Nw==&mid=2247502362&idx=1&sn=64f0c7bd266c49448ea379cf984e5439&chksm=c1973690f6e0bf86cdb5243e0f6c23a00dbbbb56eca901c97288be51f8bfbdef66f729a68bff&token=922743718&lang=zh_CN#rd"
                        >
                          <el-menu-item
                            class="header-item float-left"
                            :index="nav.titleEng.trim()"
                            >{{ nav.title }}</el-menu-item
                          >
                        </a>
                      </span>
                    </span>
                  </span>
                </span>
              </el-menu>
            </span>
          </div>
        </div>
      </el-header>
      <div style="height: 80px"></div>
      <!-- 回到顶部 -->
      <div
        style="
          position: fixed;
          right: 30px;
          z-index: 10;
          bottom: 100px;
          width: 104px;
          height: 104px;
        "
        @mouseenter="topImg = false"
        @mouseleave="topImg = true"
      >
        <a href="#" v-if="topImg"
          ><img
            style="
              border-radius: 50%;
              position: relative;
              left: -7px;
              width: 100%;
            "
            src="@/assets/images/up@2x.png"
        /></a>
        <a href="#" v-else
          ><img
            style="
              border-radius: 50%;
              position: relative;
              left: -7px;
              width: 100%;
            "
            src="@/assets/images/top-red.png"
          />
        </a>
      </div>
      <el-main class="layMain">
        <router-view @childEvent="openPopup"></router-view>
      </el-main>

      <el-footer class="layFooter flex" height="72px">
        <div>
          <span v-if="footer == null">
            版权所有 © 深圳市嘉信瑞税务师事务所有限公司
            深圳市福田区深南中路佳和华强大厦B座2509 粤ICP备2021043053号
          </span>
          <span
            style="
              font-family: PingFang SC Medium;
              font-weight: 500;
              font-size: 15px;
              line-height: 25px;
              text-align: center;
              color: #e3e2e2;
            "
            v-else
          >
            <span v-html="insertLinkInText(footer.content)"></span>
          </span>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import parameterApi from "../api/parameterApi.js";
export default {
  name: "layoutPage",
  data() {
    return {
      //菜单
      nav: null,
      // 脚部信息
      footer: null,
      navLeft: 9,
      imgLeft: 0,
      width: 1920,
      topImg: true,
    };
  },
  created() {
    window.addEventListener("resize", this.getWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  // 组件创建时运行
  mounted() {
    if (this.nav == null) {
      // 拿到菜单栏对象
      this.getParam();
    }
    if (this.footer == null) {
      // 拿到脚部
      this.getFoot();
    }
    // this.getWidth();
  },
  methods: {
    getWidth() {
      this.width = window.innerWidth;
      // if (width >= 1820) {
      //   console.log("默认布局");
      //   this.imgLeft = 16.3;
      //   this.navLeft = 252;
      // } else if (width > 1440 && width < 1820) {
      //   this.imgLeft = 11.3;
      //   this.navLeft = 282;
      // } else {
      //   this.imgLeft = 5;
      //   this.navLeft = 250;
      //   console.log("第二布局");
      // }
    },
    //获得菜单
    getParam() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      parameterApi
        .getParameter()
        .then((response) => {
          const data = response.data;
          if (data.flag == 1) {
            this.nav = data.obj;
            this.$store.commit("setParam", this.nav);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.close();
        });
    },
    getFoot() {
      parameterApi
        .getFoot()
        .then((response) => {
          const data = response.data;
          if (data.flag == 1) {
            this.footer = data.obj;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    insertLinkInText(text) {
      // 将 "一个" 替换为链接
      if (text != undefined) {
        const modifiedText = text.replace(
          "粤ICP备2021043053号",
          `<a style="color:#ccc" target="_blank" href="${this.footer.url}">粤ICP备2021043053号</a>`
        );
        return modifiedText;
      }
    },
    // 打开窗口
    openPopup(data) {
      console.log(data);
      const url =
        data == undefined
          ? "https://affim.baidu.com/unique_10310327/chat?siteId=9080829&userId=10310327&siteToken=64dff6860f564791b9d8b908cff48619"
          : data;
      // 定义弹出窗口的宽度和高度
      const width = 1200;
      const height = 800;

      // 计算窗口在屏幕中央的位置
      const left = (window.innerWidth - width - 200) / 2;
      const top = (window.innerHeight - height) / 2;
      // 打开弹出窗口
      window.open(
        url,
        "PopupWindow",
        `width=${width},height=${height},left=${left},top=${top}`
      );

      // 阻止默认链接行为，以便不跳转到链接的目标页面
      event.preventDefault();
    },
    changeImg() {
      if (this.topImg == require("@/assets/images/up@2x.png")) {
        this.topImg == require("@/assets/images/top-red.png");
      } else {
        this.topImg == require("@/assets/images/up@2x.png");
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/layout.css";
@import "../assets/css/default.css";

/deep/ .el-container.is-vertical {
  z-index: -1;
}
</style>
