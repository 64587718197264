<template>
  <div
    class="aboutMePage"
    style="height: 2841px; background-color: #f7f7f7; overflow: hidden"
  >
    <div class="HeaderBG">
      <img
        style="width: 100vw; height: 430px"
        src="@/assets/images/VCG211183404957.png"
      />
    </div>

    <div
      class="aboutNav flex"
      style="height: 56px; overflow: hidden; z-index: 100"
      :style="position"
    >
      <div class="aboutMenu">
        <el-menu
          class="el-menu-demo custom-menu"
          mode="horizontal"
          @select="handleSelect"
          text-color="#191919"
          active-text-color="#C4171E"
        >
          <a href="#section1"
            ><el-menu-item
              class="aboutMenvItem float-left"
              index="1"
              @click="showDiv(1)"
              >公司简介</el-menu-item
            ></a
          >
          <a href="#section2"
            ><el-menu-item
              class="aboutMenvItem float-left"
              index="2"
              @click="showDiv(2)"
              >价值观及使命</el-menu-item
            ></a
          >
          <a href="#section3"
            ><el-menu-item
              class="aboutMenvItem float-left"
              index="3"
              @click="showDiv(3)"
              >服务范围</el-menu-item
            ></a
          >
          <a href="#section4"
            ><el-menu-item
              class="aboutMenvItem float-left"
              index="4"
              @click="showDiv(4)"
              >荣誉资质</el-menu-item
            ></a
          >
        </el-menu>
      </div>
    </div>

    <div v-if="show1" style="height: 100px" id="section1"></div>

    <div class="elMain flex" style="margin-left: -15px">
      <span v-if="thisParam.length != 0 && aboutInfo.length != 0">
        <div class="elMainContnet">
          <!-- 公司简介 -->
          <div class="article" style="height: auto; overflow: hidden">
            <div class="articleTitle">
              <span v-for="o in thisParam" :key="o.id">
                <span v-if="o.id == 12">
                  {{ o.title }}
                  <span v-for="text in aboutInfo" :key="text.id">
                    <span v-if="text.type == o.id">
                      <div class="articleText" v-html="text.content"></div>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>

          <div v-if="show2" style="height: 10px" id="section2"></div>
          <!-- 价值观及使命 -->
          <div class="values flex">
            <div style="width: 80%">
              <div>
                <span v-for="o in thisParam" :key="o.id">
                  <span v-if="o.id == 13">
                    <span
                      class="valusTitle"
                      style="
                        font-weight: 700;
                        font-size: 40px;
                        position: relative;
                        top: 70px;
                      "
                      >{{ o.title }}
                    </span>
                    <span v-for="text in aboutInfo" :key="text.id">
                      <div style="position: relative; right: -30vw; top: -20px">
                        <br />
                        <span v-if="text.type == o.id && text.id == 3">
                          <span
                            style="font-weight: 500; font-size: 18px"
                            v-html="text.content"
                          ></span
                          ><br />
                        </span>
                        <span v-if="text.type == o.id && text.id == 4">
                          <span
                            style="font-weight: 500; font-size: 18px"
                            v-html="text.content"
                          ></span>
                        </span>
                      </div>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div v-if="show3" id="section3" style="height: 20px"></div>
        </div>

        <div class="service" style="margin-left: -5px">
          <div class="float-left flex">
            <div style="width: 1288px">
              <!-- 服务范围 -->
              <div style="width: 80%">
                <span v-for="o in thisParam" :key="o.id">
                  <span v-if="o.id == 14">
                    <!-- 标题 -->
                    <span
                      style="
                        font-weight: 700;
                        font-size: 40px;
                        position: relative;
                        top: 70px;
                      "
                      >{{ o.title }}</span
                    >
                    <!-- 内容 -->
                    <div
                      style="
                        position: relative;
                        top: 100px;
                        width: 617px;
                        line-height: 35px;
                        font-size: 16px;
                        color: #191919;
                      "
                    >
                      <span v-for="server in aboutInfo" :key="server.id">
                        <span
                          v-if="server.type == o.id"
                          v-html="server.content"
                        >
                        </span>
                      </span>
                    </div>
                  </span>
                </span>
              </div>

              <!-- 荣誉资质 -->
              <div
                class="honor"
                style="position: relative; bottom: -300px"
                id="section4"
              >
                <span v-for="o in thisParam" :key="o.id">
                  <span v-if="o.id == 15">
                    <span style="font-weight: 700; font-size: 40px">{{
                      o.title
                    }}</span>
                    <!-- 内容 -->
                    <div style="position: relative; top: 55px">
                      <span v-for="server in aboutInfo" :key="server.id">
                        <span
                          v-if="server.type == o.id"
                          style="line-height: 25px"
                        >
                          <span
                            v-html="server.content"
                            style="float: left"
                          ></span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                            style="color: #737373; font-weight: 500"
                            v-html="server.other"
                          ></span
                          ><br /><br />
                        </span>
                      </span>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div style="position: absolute; right: 0px; width: 720px">
            {{ listen(param, currentPath) }}
            <img
              style="width: 100%"
              src="@/assets/images/A985CBD9-3A75-4179-A17D-5D16D87A60AA.png"
            />
          </div>
        </div>
      </span>
      <span v-else>
        <div class="elMainContnet">
          <!-- 公司简介 -->
          <div class="article">
            <div
              class="articleTitle"
              style="text-align: center; font-weight: 700; font-size: 40px"
            >
              {{ article.title }}
              <div class="articleText" v-html="article.text"></div>
            </div>
          </div>
          <!-- 价值观及使命 -->
          <div class="values flex">
            <div style="width: 80%">
              <div>
                <span
                  style="
                    font-weight: 700;
                    font-size: 40px;
                    position: relative;
                    top: 70px;
                  "
                  >{{ values.title }}
                </span>
                <div
                  style="
                    position: absolute;
                    right: 20%;
                    font-weight: 500;
                    font-size: 18px;
                  "
                  v-html="values.text"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="service">
          <div class="float-left flex">
            <div style="width: 1265px">
              <!-- 服务范围 -->
              <div>
                <!-- 标题 -->
                <span
                  style="
                    font-weight: 700;
                    font-size: 40px;
                    position: relative;
                    top: 70px;
                  "
                  >{{ service.title }}</span
                >
                <!-- 内容 -->
                <div
                  style="
                    position: relative;
                    top: 120px;
                    width: 617px;
                    line-height: 35px;
                    font-size: 16px;
                    color: #191919;
                  "
                  v-html="service.text"
                ></div>
              </div>

              <!-- 荣誉资质 -->
              <div class="honor" style="position: relative; bottom: -250px">
                <span style="font-weight: 700; font-size: 40px; float: left">
                  {{ honor.title }}</span
                >
                <!-- 内容 -->
                <div
                  style="
                    position: relative;
                    top: 90px;
                    float: left;
                    left: -160px;
                  "
                  v-html="honor.text"
                ></div>
              </div>
            </div>
          </div>
          <div style="position: absolute; right: 0px; width: 720px">
            {{ listen(param, currentPath) }}
            <img
              style="width: 100%"
              src="@/assets/images/A985CBD9-3A75-4179-A17D-5D16D87A60AA.png"
            />
          </div>
        </div>
      </span>
    </div>
  </div>
</template>
  
<script>
import parameterApi from "@/api/parameterApi";
export default {
  name: "aboutMePage",
  data() {
    return {
      article: {
        title: "公司简介",
        text: `深圳市嘉信瑞税务师事务所有限公司于2007年1月17日经深圳市国税局和深圳市地税局及深圳市注册税务师管理中心批准成立为具备执业资格的税务专业服务机构。<br /><br />
        营业执照号：91440300797996748Q<br />
        <br />
        深圳市嘉信瑞税务师事务所有限公司，是一家能够给客户提供更多专业、优质、高附加价值的服务，且在行业内卓越领先的大型税务师事务所。本所已于2012年12月，经深圳市国家税务局考核，取得AAA 级事务所，在深圳170多家事务所中（不包括分所），取得AAA级事务所仅十家，评选情况已在深圳国税、深圳地税网上公告。<br />
        <br />
        本所拥有一批精通会计、审计、税务、法律、资产评估及管理咨询方面的中高级专业人才。他们在基础税务服务至高端税务服务以及与税务机关的协调沟通中，都积累了丰富的专业服务经验。<br />
        <br />
        多年来，嘉信瑞为多家公司提供土地增值税清算、税务顾问、企业所得税汇算清缴审核及代理、财产损失审核鉴证、税务策划、专项申报代理及税务培训等专业服务，曾服务过的客户有：永亨银行、中铁置业、高银地产、浙商银行、三九医药、手抖机场地产集团、海航置业、大地影院等。<br />
        <br />
        在多年的专业服务中，嘉信瑞凭借专业的服务水平、良好的沟通协调能力、高效和积极的热情，以及坚定进取的服务精神赢得了客户的充分信赖、高度评价和不断的支持，且在业界和社会上获得了良好的声誉。<br />
        <br />
        深圳市嘉信瑞税务师事务所有限公司，一直秉承客观、公正、诚信、进取的服务宗旨，以专业、优质、可靠的服务，为广大客户服务！<br />
        <br"`,
      },
      service: {
        title: "服务范围",
        text: `本所拥有一批精通会计、审计、税务、法律、资产评估及管理咨询方面的中高级专业人才。他们在基础税务服务至高端税务服务以及与税务机关的协调沟通中，都积累了丰富的专业服务经验。<br/><br/>
多年来，嘉信瑞为多家公司提供土地增值税清算、税务顾问、企业所得税汇算清缴审核及代理、财产损失审核鉴证、税务策划、专项申报代理及税务培训等专业服务，曾服务过的客户有：永亨银行、中铁置业、高银地产、浙商银行、三九医药、手抖机场地产集团、海航置业、大地影院等。`,
      },
      values: {
        title: "价值观及使命",
        text: `<br/>用爱心做事业，用感恩的心做人！<br/><br/>用专业的服务，为社会创造更大的价值！`,
      },
      honor: {
        title: "荣誉资质",
        text: `在福田会计工作中表现突出，被授予2022年年度「会计工作先进集体」荣誉称号 &nbsp;&nbsp;2023.2.22<br/><br/>
        在福田会计工作中表现突出，被授予2020年年度「会计工作先进集体」荣誉称号 &nbsp;&nbsp;2021.1.16<br/><br/>
        在2019年福田区规模以上服务业统计工作中，成绩显著，授予「先进企业」称号  &nbsp;&nbsp;2019.12.17<br/><br/>
        荣获2018年度广东省会计师事务所「综合评价百强」&nbsp;&nbsp;2018.12.28<br/><br/>
        在2010年度福田区其他服务业统计工作中，成绩显著，授予「先进企业」称号&nbsp;&nbsp;2011.01.06
        `,
      },
      aboutInfo: [],
      thisParam: [],
      flag: false,
      position: "",
      scrollPosition: 0,
      oldPosition: 0,
      show1: false,
      show2: false,
      show3: false,
    };
  },
  computed: {
    param() {
      return this.$store.state.param;
    },
    currentPath() {
      return window.location.pathname;
    },
  },
  mounted() {
    this.getAboutUs();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // 在组件销毁前移除滚动事件监听器，以防止内存泄漏
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    showDiv(id) {
      this.oldPosition = this.scrollPosition;
      if (id == 1) {
        this.show1 = true;
        this.show2 = false;
        this.show3 = false;
      } else if (id == 2) {
        this.show2 = true;
        this.show1 = false;
        this.show3 = false;
      } else if (id == 3) {
        this.show3 = true;
        this.show1 = false;
        this.show2 = false;
      } else {
        this.show3 = false;
        this.show1 = false;
        this.show2 = false;
      }
    },
    handleScroll() {
      if (this.oldPosition != this.scrollPosition) {
        this.show3 = false;
        this.show1 = false;
        this.show2 = false;
      }
      // 更新滚动位置
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > 400) {
        this.position = "position:fixed;top:80px";
      } else {
        this.position = "";
      }
    },
    // 拿到param列表监听变化,将属于该页面的标签加入thisParam数组
    listen(param, path) {
      if (param != null && !this.flag && path != null) {
        const newPath = path.substring(1, path.length);
        param.forEach((a) => {
          if (a.titleEng != null && a.titleEng.includes(newPath)) {
            param.forEach((b) => {
              if (b.pid == a.id) {
                this.thisParam.push(b);
              }
            });
            this.flag = true;
          }
        });
      }
    },
    getAboutUs() {
      parameterApi
        .getAboutUs()
        .then((response) => {
          const data = response.data.obj;
          this.aboutInfo = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
  
<style scoped>
@import url("../../assets/css/default.css");
@import url("../../assets/css/aboutMe.css");

.aboutNav {
  height: 58px;
  overflow: hidden;
  background-color: #fff;
}

.aboutMenu {
  width: 80vw;
  max-width: 1290px;
}

.aboutMenvItem {
  width: 25%;
  font-size: 16px;
  text-align: center;
}

.aboutMenvItem:hover {
  color: #c4171e !important;
  background-color: #f2f2f2 !important;
}

.custom-menu .el-menu-item.is-active.is-active,
.custom-menu .el-submenu.is-active {
  background-color: #f3f3f3 !important;
}

.elMainContnet {
  position: relative;
  width: 80vw;
  top: 110px;
  max-width: 1290px;
  color: #191919;
}

.articleTitle {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
}

.articleText {
  width: 1290px;
  margin-top: 50px;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  text-align: left;
  color: #191919;
}

.values {
  position: relative;
  top: 140px;
  width: 1290px;
  height: 283px;
  background-color: #f1f1f2;
}

.service {
  position: absolute;
  left: 0px;
  bottom: 620px;
  width: 100vw;
  height: 528px;
  color: #191919;
  background-color: #fff;
}
</style>
  

