import request from "@/request";

export default{
    getParameter(){
        return request.get('/sys-parameter/list')
    },
    getFoot(){
        return request.get('/about-us/getFoot')
    },
    getProduct(){
        return request.get('/product/getList')
    },
    getNewsType(){
        return request.get('/news-type/newsType')
    },
    getNews(){
        return request.get('/news/getTopNews')
    },
    getAboutUs(){
        return request.get('/about-us/getList')
    },
    getOffice(){
        return request.get('/company-info/list')
    },
    getNewsPage(data){
        return request.get('/news/getNewsList',data)
    },
    getNewsData(data){
        return request.get('/news/getNewsContent',data)
    },
    assist(data){
        return request.get('/news/assist',data)
    },
    getWork(data){
        return request.get('/party-building-news/list',data)
    }
    
}