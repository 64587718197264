<template>
  <div class="workPage">

    <div class="HeaderBG">
      <img style="width: 100vw;height: 430px;" src="@/assets/images/2684786-01.png" />
      <!-- 背景图内容 -->
      <div class="flex" style="color:#191919">

        <div style="position: absolute;width:1290px;top: 210px;">

          <span class="carouselBlank">党建新闻</span>

          <br /><br />

          <span style="font-weight: 700;font-size: 17px;margin-left:-15px">牢记使命,不忘初心</span>

          <br /><br />


        </div>

      </div>
    </div>


    <div class="aboutNav flex">
      <div class="aboutMenu">
        <el-menu class="el-menu-demo custom-menu" mode="horizontal" @select="handleSelect" text-color="#191919"
          active-text-color="#C4171E">
          <span v-for="o in param" :key=o.id>
            <span v-if="o.type == 5 && o.pid != null">
              <el-menu-item class="aboutMenvItem float-left" :index="o.title" @click="getWork(o.id)">{{ o.title
              }}</el-menu-item>
            </span>

          </span>

        </el-menu>

      </div>

    </div>

    <div class="elMain flex" style="width: 100%;background: #F7F7F7;">
      <div class="elMainCnt" style="margin-top: 50px;position: relative;">
        <div style="width: 1290px;">
          <span v-for="o in works" :key="o.id">
            <div class="workDes">

              <p style="width: 70%;text-overflow: ellipsis;overflow: hidden;white-space:nowrap;">
                <el-tooltip :content="o.titleDesc" placement="bottom" effect="light">
                  <a target="_blank" style="text-decoration: none;color:#191919" :href="o.url">
                    ·{{ o.title }}
                  </a>
                </el-tooltip>

                &nbsp;&nbsp;&nbsp;&nbsp;

              </p>

              <span class="workDate">
                {{ o.date }}
              </span>
            </div>
          </span>
        </div>

        <span v-if="pageNum < 0">

        </span>
        <span v-else>
          <div class="flex" style="position: relative;margin-top: 91px;">

            <span>
              <el-button @click="goPrev()" :disabled="prev" style="padding:8px;width: 93px;"><img
                  src="@/assets/images/arrow-down(3).png" style="position: relative;top: 2px;">&nbsp;&nbsp;上一页</el-button>
            </span>

            <el-pagination @current-change="handleCurrentChange" background :current-page="pageNum" :page-size=pageSize
              layout="pager" :total="total">
            </el-pagination>
            <span><el-button :disabled="next" style="padding:8px;width: 93px;" @click="goNext()"> 下一页&nbsp;&nbsp;<img
                  src="@/assets/images/arrow-down.png" style="position: relative;top: 2px;"></el-button></span>

            <span style="position: relative;left: 15px;top: 5px;font-size: 15px;">
              <span style="color:#C4171E">{{ pageNum }}</span>/{{ Math.ceil(total / pageSize) }}
            </span>

            <span style="position: relative;left: 30px;top: -3px;">
              <span style="color:#8B8B8B;font-family: PingFang SC Medium;font-weight: 500;font-size: 15px;">到第</span>
              <el-input type="text" v-model="search"
                style="width: 48px;margin-left: 15px;margin-right: 15px;padding: 0px;"></el-input>
              <span style="color:#8B8B8B;font-family: PingFang SC Medium;font-weight: 500;font-size: 15px;">页</span>

              <el-button @click="searchPage(search)"
                style="padding:8px;width:53px;color: #191919;margin-left: 15px;">确定</el-button>
            </span>

          </div>
        </span>
        <div style="height: 200px;">

        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import parameterApi from '@/api/parameterApi.js'
export default {
  name: 'workPage',
  data() {
    return {
      navFlag: 30,
      currentPage: 1,
      pageNum: 1,
      pageSize: 8,
      total: null,
      prev: null,
      next: null,
      search: null,
      works: [
        {
          id: 1,
          title: '文字文字文字文字文字',
          date: '2023-01-01  15:40',
          isTop: null,
          titleDesc: null,
          type: 30
        },
        {
          id: 2,
          title: '文字文字文字文字文字文字',
          date: '2023-01-01  15:40',
          isTop: null,
          titleDesc: null,
          type: 30
        },
        {
          id: 3,
          title: '文字文字文字文字文字文字文字',
          date: '2023-01-01  15:40',
          isTop: null,
          titleDesc: null,
          type: 30
        },
        {
          id: 4,
          title: '文字文字文字文字文字',
          date: '2023-01-01  15:40',
          isTop: null,
          titleDesc: null,
          type: 30
        }
      ],
    }
  },
  computed: {
    param() {
      return this.$store.state.param == null ? 'param' : this.$store.state.param
    }
  },
  mounted() {
    this.handleCurrentChange(this.pageNum);
  },
  methods: {
    handleCurrentChange(val) {
      this.prev = val == 1;
      this.pageNum = val;
      console.log(`从数据库查找第: ${val}` + '页');
      parameterApi.getWork({
        params: {
          id: this.navFlag,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }).then(response => {
        const data = response.data.obj;
        this.works = data.object;
        this.total = data.totalCount;
        this.next = Math.ceil(this.total / this.pageSize) == val;
      }).catch(err => {
        console.log(err);
      })
    },
    getWork(id) {
      this.navFlag = id;
      this.handleCurrentChange(1);
    },
    searchPage(page) {
      if (page < 1) {
        this.$message({
          message: '请输入大于0的数',
          type: 'info',
          duration: 1000
        })
        return;
      }
      page = parseInt(page);
      const msg = isNaN(page) ? '请输入数字' : Math.ceil(this.total / this.pageSize) < page ? '超过最大页数' : null;
      console.log(msg);

      if (msg != null) {
        this.$message({
          message: msg,
          type: 'info',
          duration: 1000
        })
        return;
      }

      const number = parseInt(page);
      this.handleCurrentChange(number);
      this.search = null;
    },
    goNext() {
      this.handleCurrentChange(this.pageNum + 1);
    },
    goPrev() {
      this.handleCurrentChange(this.pageNum - 1);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  }
}
</script>

<style scoped>
@import url('../../assets/css/default.css');

.aboutNav {
  height: 56px;
  overflow: hidden;
  background-color: #fff;
}

.aboutMenu {
  width: 80vw;
  max-width: 1290px;
}

.aboutMenvItem {
  width: 20%;
  font-size: 16px;
  text-align: center;
}

.aboutMenvItem:hover {
  color: #C4171E !important;
  background-color: #F2F2F2 !important;
}

.custom-menu .el-menu-item.is-active.is-active,
.custom-menu .el-submenu.is-active {
  background-color: #F3F3F3 !important;
}




.workDes {
  height: 46px;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 25px;
  font-family: PingFang SC Bold;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: #191919;
}

.workDate {
  position: relative;
  right: 10px;
  top: -20px;
  float: right;
  font-family: PingFang SC Medium;
  font-weight: 500;
  font-size: 16px;
  color: #454545;
}

::v-deep .el-pagination.is-background .el-pager li {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ff2832;
}
</style>
  

