<template>
  <div class="customPage">
    <!-- 咨询按钮,固定 -->
    <buttonView @childEvent="$emit('childEvent')"></buttonView>

    <div class="HeaderBG">
      <img
        style="width: 100vw; height: 430px"
        src="@/assets/images/110000.png"
      />
      <!-- 背景图内容 -->
      <div class="flex" style="color: #191919">
        <div style="position: absolute; width: 1290px; top: 210px">
          <span class="carouselBlank">成功案例</span>

          <br /><br />

          <span class="carouselDes">服务过上万家企业，品牌值得信赖！</span>

          <br /><br />
        </div>
      </div>
    </div>

    <div class="elMain flex">
      <div class="elMainCnt" style="overflow: hidden">
        <p
          style="
            font-family: PingFang SC Bold;
            font-weight: 700;
            font-size: 34px;
            text-align: center;
          "
        >
          客户案例
        </p>
        <br />
        <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
          <el-tab-pane
            :label="tab.label"
            :name="tab.name"
            v-for="tab in eltab"
            :key="tab.id"
          >
            <br />
            <ul style="list-style: none">
              <span v-for="o in tab.data" :key="o.id">
                <!-- 为4时,不要margin-right属性 -->
                <span v-if="o.id % 4 != 0">
                  <li
                    class="float-left elTab"
                    style="margin-left: 1px; margin-right: 28px"
                  >
                    <img style="position: relative" :src="o.img" />

                    <p class="tabText">
                      {{ o.title }}
                    </p>
                    <br />
                    <p class="tabText tabDes">
                      {{ o.des }}
                    </p>
                  </li>
                </span>
                <span v-else>
                  <li class="float-left elTab" style="margin-right: 1px">
                    <img style="position: relative" :src="o.img" />

                    <p class="tabText">
                      <span
                        v-if="
                          o.title == '深圳市人力资源和社会保障局罗湖分局' ||
                          o.title == '海德(广州)国际物流集团有限公司上海分公司'
                        "
                        :style="
                          o.title == '深圳市人力资源和社会保障局罗湖分局'
                            ? 'font-size: 15px'
                            : 'font-size:13px'
                        "
                      >
                        {{ o.title }}
                      </span>
                      <span v-else>
                        {{ o.title }}
                      </span>
                    </p>
                    <br />
                    <p class="tabText tabDes">
                      {{ o.des }}
                    </p>
                  </li>
                </span>
              </span>
            </ul>
          </el-tab-pane>
        </el-tabs>

        <div style="height: 70px"></div>
      </div>
    </div>

    <div class="elMain flex" style="background-color: #f7f7f7">
      <div class="elMainCnt">
        <p
          style="
            font-family: PingFang SC Bold;
            font-weight: 700;
            font-size: 34px;
            text-align: center;
          "
        >
          优秀合作伙伴
        </p>
        <br /><br />
        <ul style="list-style: none; overflow: hidden">
          <span v-for="o in images" :key="o.id">
            <span v-if="o.id % 5 != 0">
              <li
                class="float-left"
                style="margin-right: 50px; margin-bottom: 50px"
              >
                <img :src="o.img" />
              </li>
            </span>
            <span v-else>
              <li class="float-left">
                <img :src="o.img" />
              </li>
            </span>
          </span>
        </ul>
        <div style="height: 100px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonView from "@/components/buttonView";
export default {
  components: {
    buttonView,
  },
  name: "customPage",
  data() {
    return {
      images: [
        {
          id: 1,
          img: require("@/assets/images/2(2).png"),
        },
        {
          id: 2,
          img: require("@/assets/images/14(1).png"),
        },
        {
          id: 3,
          img: require("@/assets/images/7.png"),
        },
        {
          id: 4,
          img: require("@/assets/images/14.png"),
        },
        {
          id: 5,
          img: require("@/assets/images/13(1).png"),
        },
        {
          id: 6,
          img: require("@/assets/images/5.png"),
        },
        {
          id: 7,
          img: require("@/assets/images/9(1).png"),
        },
        {
          id: 8,
          img: require("@/assets/images/6.png"),
        },
        {
          id: 9,
          img: require("@/assets/images/8.png"),
        },
        {
          id: 10,
          img: require("@/assets/images/9.png"),
        },
        {
          id: 11,
          img: require("@/assets/images/10.png"),
        },
        {
          id: 12,
          img: require("@/assets/images/11.png"),
        },
        {
          id: 13,
          img: require("@/assets/images/15.png"),
        },
        {
          id: 14,
          img: require("@/assets/images/13.png"),
        },
        {
          id: 15,
          img: require("@/assets/images/4(1).png"),
        },
        {
          id: 16,
          img: require("@/assets/images/6(1).png"),
        },
        {
          id: 17,
          img: require("@/assets/images/2(3).png"),
        },
        {
          id: 18,
          img: require("@/assets/images/5(1).png"),
        },
        {
          id: 19,
          img: require("@/assets/images/18.png"),
        },
        {
          id: 20,
          img: require("@/assets/images/12.png"),
        },
      ],
      eltab: [
        {
          id: 1,
          name: "first",
          label: "新三板上市企业",
          data: [
            {
              id: 1,
              img: require("@/assets/images/logo-1(3).png"),
              title: "永晟新能源",
              des: "是一家致力于可充式锂离子电池，研发、生产和营销为一体的高科技企业，产量规划为15万只/日。",
            },
            {
              id: 2,
              img: require("@/assets/images/logo-2(6).png"),
              title: "圣帕新材料股份有限公司",
              des: "2007年成立，主要从事化学原料和化学制品制造业，是国内具有国际先进技术水平的太阳能电池背板主要供应商之一。",
            },
            {
              id: 3,
              img: require("@/assets/images/logo-3(4).png"),
              title: "武汉博润通文化有限公司",
              des: "成立于2010年，以原创动漫研发出品、动漫应用服务、文旅融合等为主要业务，获得19项国家级奖项。",
            },
            {
              id: 4,
              img: require("@/assets/images/logo-4(4).png"),
              title: "东阿百年堂",
              des: "致力于研发、生产和销售高品质的个人健康美丽产品,以增进全球每一个家庭的健康与美丽。",
            },
          ],
        },
        {
          id: 2,
          name: "second",
          label: "金融",
          data: [
            {
              id: 1,
              img: require("@/assets/images/logo-1(2).png"),
              title: "联泰基金",
              des: "成立于2014年6月，并于2014年10月获得中国证监会颁发的基金销售牌照，是中国证券业基金协会会员。",
            },
            {
              id: 2,
              img: require("@/assets/images/logo-4(3).png"),
              title: "金蝶",
              des: "始创于1993 年，金蝶通过管理软件与云服务，已为世界范围内680万家企业 、政府提供服务。",
            },
            {
              id: 3,
              img: require("@/assets/images/logo-3(3).png"),
              title: "清研睿合",
              des: "是一家致力于股权投资基金管理的专业机构，专注于发展股权投资、兼并收购与上市顾问业务。",
            },
            {
              id: 4,
              img: require("@/assets/images/logo-2(3).png"),
              title: "深圳市人力资源和社会保障局罗湖分局",
              des: "协助区组织部门制订全区干部培训规划，按干部管理权限，负责全区公务员、职员、军转干部的培训工作。",
            },
          ],
        },
        {
          id: 3,
          name: "three",
          label: "制造",
          data: [
            {
              id: 1,
              img: require("@/assets/images/logo-1(10).png"),
              title: "深圳周大福珠宝制造有限公司",
              des: "成立于2012年，从事珠宝首饰及有关饰品、钟表、工艺品、包装制品及相关生产工具、工艺设备等。",
            },
            {
              id: 2,
              img: require("@/assets/images/logo-4(2).png"),
              title: "远荣智能",
              des: "是一家专业为制造企业提供智能制造成套装备的综合服务商，是集研发、生产、销售、安装、服务为一体高新技术企业。",
            },
            {
              id: 3,
              img: require("@/assets/images/logo-3(2).png"),
              title: "恒安警用装备",
              des: "是集研发、生产、销售、服务为一体的专业制造公司，警标委通信委员单位，警标委《金属手铐》、《金属脚镣》行业标准起草单位。",
            },

            {
              id: 4,
              img: require("@/assets/images/logo-2(2).png"),
              title: "上海润元家具",
              des: "成立于2007年，致力与空间设计及家具设计、生产、销售，本着务实专业的理念，孜孜以求,努力促进行业繁荣。",
            },
          ],
        },
        {
          id: 4,
          name: "four",
          label: "新零售",
          data: [
            {
              id: 1,
              img: require("@/assets/images/logo-1(3).png"),
              title: "永晟新能源",
              des: "是一家致力于可充式锂离子电池，研发、生产和营销为一体的高科技企业，产量规划为15万只/日。",
            },
            {
              id: 2,
              img: require("@/assets/images/logo-2(6).png"),
              title: "圣帕新材料股份有限公司",
              des: "2007年成立，主要从事化学原料和化学制品制造业，是国内具有国际先进技术水平的太阳能电池背板主要供应商之一。",
            },
            {
              id: 3,
              img: require("@/assets/images/logo-3(4).png"),
              title: "武汉博润通文化有限公司",
              des: "成立于2010年，以原创动漫研发出品、动漫应用服务、文旅融合等为主要业务，获得19项国家级奖项。",
            },
            {
              id: 4,
              img: require("@/assets/images/logo-4(4).png"),
              title: "东阿百年堂",
              des: "致力于研发、生产和销售高品质的个人健康美丽产品,以增进全球每一个家庭的健康与美丽。",
            },
          ],
        },
        {
          id: 5,
          name: "five",
          label: "医疗健康",
          data: [
            {
              id: 1,
              img: require("@/assets/images/logo-1(1).png"),
              title: "深圳大微医疗科技有限公司",
              des: "一家专注于心血管领域的、基于数据和创新双轮驱动的远程心脏监测高新技术企业。",
            },
            {
              id: 2,
              img: require("@/assets/images/logo-4(1).png"),
              title: "敏睿高医疗器械",
              des: "是以医疗器械研发、生产、销售、商业配送为一体的综合性医疗器械企业，专注于新医疗器械的研发及引进国外新型器械技术。",
            },
            {
              id: 3,
              img: require("@/assets/images/logo-3(1).png"),
              title: "康膝生物医疗(深圳)有限公司",
              des: "是一家集骨科医疗器械研发、注册、生产与销售于一体的技术企业，致力于研发、生产一种国际领先的组织工程支架材料。",
            },
            {
              id: 4,
              img: require("@/assets/images/logo-2(1).png"),
              title: "深圳多特医疗",
              des: "一家高科技医疗设备及医疗信息化软件研发、制造、销售厂商，主要发展方向为互联网+医疗健康。",
            },
          ],
        },
        {
          id: 6,
          name: "six",
          label: "交通物流",
          data: [
            {
              id: 1,
              img: require("@/assets/images/logo-1.png"),
              title: "深圳市大田仓储物流有限公司",
              des: "于2005年11月25日成立，是大田集团旗下的分公司，能够为客户提供综合物流服务。",
            },
            {
              id: 2,
              img: require("@/assets/images/logo-4.png"),
              title: "深圳市南冠物流有限公司",
              des: "是中国第一家专注于半导体行业运输服务的物流服务商，致力于为行业提供专业、精准、可靠、高效的物流及配套服务。",
            },
            {
              id: 3,
              img: require("@/assets/images/logo-3.png"),
              title: "深圳市五洲物流有限公司",
              des: "成立于2002年，是一家专业经营进出口海陆空多式联运的物流公司，已获交通部颁发“无船承运业务资格”证书。",
            },
            {
              id: 4,
              img: require("@/assets/images/logo-2.png"),
              title: "海德(广州)国际物流集团有限公司上海分公司",
              des: "是涵盖电商服务、仓储、整车、FBA、海外仓，国际集装箱运输，拖车报关等业务的大型现代综合物流企业。",
            },
          ],
        },
      ],
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
@import url("../../assets/css/default.css");

.elTab {
  height: 224px;
  width: 300px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 8px rgba(0, 0, 0, 0.2);
}

.tabText {
  width: 88%;
  position: relative;
  left: 20px;
}

.tabDes {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #454545;
  overflow: hidden;
  height: 96px;
  margin-top: -5px;
}

::v-deep .el-tabs__item {
  font-family: PingFang SC Bold;
  font-weight: 700;
  font-size: 15px;
}

::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: #c4171e;
}

::v-deep .el-tabs__active-bar {
  background-color: #c4171e;
}
</style>
